<script>
import SimpleQuestion from '@/components/forms/inputGroups/SimpleQuestion';
import FreeTypeYearQuestion from '@/components/forms/inputGroups/FreeTypeYearQuestion';
import AddressLookup from '@/components/forms/inputGroups/addressLookup/AddressLookup';
import PreviousAddress from '@/components/forms/inputGroups/addressLookup/PreviousAddress';
import DatePicker from '@/components/DatePicker';
import Modal from '@/components/Modal';
import PoliticallyExposedPersonInfo from '@/components/messaging/PoliticallyExposedPersonInfo';
import nationalities from '@/components/forms/selectValues/nationalities';
import titles from '@/components/forms/selectValues/titles';
import { mapState } from 'vuex';

const yesOrNo = {
  true: 'Yes',
  false: 'No',
};

const genderValues = [
  { value: null, text: 'Please choose', disabled: true },
  { value: 'female', text: 'Female' },
  { value: 'male', text: 'Male' },
];

const relationships = [
  { value: null, text: 'Please choose', disabled: true },
  { value: 'Spouse', text: 'Spouse' },
  { value: 'Partner', text: 'Partner' },
  { value: 'Child', text: 'Child' },
  { value: 'Relative', text: 'Relative' },
  { value: 'Tenant', text: 'Tenant' },
  { value: 'Other', text: 'Other' },
];

const rawCorrespondenceAddress = {
  organisation_name: '',
  premise: '',
  thoroughfare: '',
  dependent_locality: '',
  locality: '',
  subAdministrativeArea: '',
  administrative_area: '',
  postal_code: '',
};

const rawPreviousAddress = {
  address: {
    organisation_name: '',
    premise: '',
    thoroughfare: '',
    dependent_locality: '',
    locality: '',
    subAdministrativeArea: '',
    administrative_area: '',
    postal_code: '',
  },
  start_date: null,
  end_date: null,
};

export default {
  components: {
    DatePicker,
    SimpleQuestion,
    FreeTypeYearQuestion,
    AddressLookup,
    PreviousAddress,
    Modal,
    PoliticallyExposedPersonInfo,
  },
  name: 'PersonQuestionnaire',
  data() {
    return {
      currentPreviousAddress: { ...rawPreviousAddress },
      showDuplicatedClientModal: false,
      visibleForm: false,
      editing: false,
      showRelationshipQuestion: false,
      showPepInfo: false,
      yesOrNo,
      titles,
      nationalities,
      genderValues,
      relationships,
      personData: {
        personId: '',
        title: null,
        noMiddleNameConfirmed: false,
        firstName: '',
        middleName: '',
        lastName: '',
        previousName: '',
        dob: null,
        gender: null,
        nationality: null,
        correspondenceAddress: { ...rawCorrespondenceAddress },
        previousAddresses: [],
        phoneHome: '',
        phoneMobile: '',
        phoneWork: '',
        email: '',
        occupation: '',
        niNumber: '',
        nameChanged: null,
        nameChangedDetails: '',
        bankrupt: null,
        bankruptDate: null,
        availability: '',
        propertyInterestsOwned: null,
        relationship: null,
        relationshipOther: '',
        politicallyExposedPerson: null,
      },
      editingAddressIndex: null,
      phoneHomeIsRequired: true,
      phoneMobileIsRequired: true,
    };
  },
  props: {
    value: {
      type: Object,
      required: true,
    },
  },
  beforeMount() {
    this.personData = { ...this.value };
    this.showRelationshipQuestion = !this.personData?.cognitoUserId;
    if (!this.showRelationshipQuestion) {
      this.personData.relationship = null;
    }
  },
  methods: {
    /**
     * Generate validation message for each field
     *
     * @param dirty
     * @param validated
     * @param valid
     * @returns {*}
     */
    getValidationState({ dirty, validated, valid = null }) {
      this.phoneHomeIsRequired = !(
        this.personData?.phoneMobile !== '' &&
        this.personData?.phoneMobile !== null &&
        typeof this.personData?.phoneMobile !== 'undefined'
      );
      this.phoneMobileIsRequired = !(
        this.personData?.phoneHome !== '' &&
        this.personData?.phoneHome !== null &&
        typeof this.personData?.phoneHome !== 'undefined'
      );
      return dirty || validated ? valid : null;
    },
    /**
     * This method adds a new previous address or appends a old one
     * @returns {Promise<boolean>}
     */
    async addPreviousAddress() {
      const isValid = await this.$refs.previousAddressForm.$refs.observer.validate();
      if (!isValid) {
        this.$el.querySelector('[aria-invalid=true]').focus();
        return false;
      }
      if (this.editingAddressIndex !== null) {
        this.personData.previousAddresses.splice(this.editingAddressIndex, 1, { ...this.currentPreviousAddress });
        this.currentPreviousAddress = { ...rawPreviousAddress };
        this.visibleForm = false;
        this.editingAddressIndex = null;
      } else {
        this.personData.previousAddresses.push({ ...this.currentPreviousAddress });
        this.currentPreviousAddress = { ...rawPreviousAddress };
        this.visibleForm = false;
      }
    },
    checkDuplicateClient() {
      let personToCompare = this.s_persons
        .filter((person) => {
          return person.personId !== this.personData.personId;
        })
        .map((v) => ({
          firstName: v.firstName,
          lastName: v.lastName,
          dob: v.dob,
        }));
      // Ensure we only run the compare on an additional client not the main client on first attempt of filling out the form
      if (personToCompare.length !== 0) {
        const curretClientDob = new Date(personToCompare[0]['dob']).setHours(0, 0, 0, 0);
        const newClientDob = new Date(this.personData.dob).setHours(0, 0, 0, 0);
        if (
          personToCompare[0]['firstName'].toLowerCase().trim() == this.personData.firstName.toLowerCase().trim() &&
          personToCompare[0]['lastName'].toLowerCase().trim() == this.personData.lastName.toLowerCase().trim() &&
          curretClientDob === newClientDob
        ) {
          this.showDuplicatedClientModal = true;
          return true;
        }
      }
    },
    closeDuplicatedClient() {
      return (this.showDuplicatedClientModal = false);
    },
    async triggerUpdate() {
      if (this.checkDuplicateClient() == true) {
        return false;
      }
      const isValid = await this.$parent.$parent.$refs.observer.validate();
      if (isValid) {
        this.$emit('input', this.personData);
      } else {
        const element = this.$el.querySelector('[aria-invalid=true]');
        const offset = document.getElementById('top-nav').offsetHeight + 10;
        const bodyRect = document.body.getBoundingClientRect().top;
        const elementRect = element.getBoundingClientRect().top;
        const elementPosition = elementRect - bodyRect;
        const offsetPosition = elementPosition - offset;

        window.scrollTo({
          top: offsetPosition,
          behavior: 'smooth',
        });
      }
    },
    editPreviousAddress(index) {
      this.visibleForm = true;
      this.editingAddressIndex = index;
      this.currentPreviousAddress = this.personData.previousAddresses[index];
    },
    removePreviousAddress(index) {
      this.personData.previousAddresses.splice(index, 1);
    },
    togglePepInfo(value) {
      this.showPepInfo = value;
    },
  },
  computed: {
    ...mapState({
      s_persons: (state) => state.persons.clients,
    }),
    /**
     * Getter and setter for previous address. It is needed for reactivity of changes is previous addresses
     */
    previousAddresses: {
      get() {
        return this.personData.previousAddresses;
      },
      set(value) {
        this.personData.previousAddresses = value;
      },
    },
    /**
     * We need to show in the Client Questionnaire previous addresses for the last 12 months
     * @returns {[]}
     */
    actualPreviousAddresses: function () {
      let result = [];
      this.previousAddresses.forEach((item, index) => {
        result.push({
          address: item,
          index,
        });
      });
      return result;
    },
    /**
     * This method returns an object of dates used for validation or editing of previous addresses.
     *
     * @returns {{from: Date, to: Date}[]|*[]}
     */
    rangesOfPreviousAddresses: function () {
      if (this.personData.previousAddresses && this.personData.previousAddresses.length > 0) {
        return this.personData.previousAddresses.map((el) => ({
          from: new Date(el.start_date),
          to: new Date(el.end_date),
        }));
      }
      return [];
    },
    /**
     * this method is used as props in vue-datepicker to disable future dates.
     *
     * @returns {{disabledDates: {from: Date}}}
     */
    dateConstraint: function () {
      return {
        disabledDates: {
          from: new Date(),
        },
      };
    },
    /**
     * Getter and setter for bankrupt, it is used in SimpleQuestion component, and needs to be wrapped in object
     */
    bankrupt: {
      get() {
        return {
          answer: this.personData.bankrupt,
        };
      },
      set(value) {
        this.personData.bankrupt = value.answer;
      },
    },
    bankruptDate: {
      get() {
        return {
          date: this.personData.bankruptDate,
        };
      },
      set(value) {
        this.personData.bankruptDate = value.date;
      },
    },
    middleNameSet: function () {
      if (!this.personData) {
        return false;
      }

      return this.personData?.middleName?.length > 0;
    },
    // Create object containing the other clients details so that validation messages can display to the end user who is using an email address
    otherClientEmails: function () {
      return this.s_persons
        .filter((person) => {
          return person.personId !== this.personData.personId;
        })
        .map((v) => ({
          email: v.email,
          firstName: v.firstName,
          lastName: v.lastName,
        }));
    },
    emailRules: function () {
      return {
        required: true,
        email: true,
        unique_email: this.otherClientEmails,
        valid_email_domain: true,
      };
    },
    otherClientPhoneMobileNumbers: function () {
      return this.s_persons
        .filter((person) => {
          return person.personId !== this.personData.personId;
        })
        .map((v) => ({ mobile: v.phoneMobile, firstName: v.firstName, lastName: v.lastName }));
    },
    phoneMobileRules: function () {
      return {
        required: this.phoneMobileIsRequired,
        mobile_number: true,
        unique_phone_mobile: this.otherClientPhoneMobileNumbers,
      };
    },
    otherClientPhoneHomeNumbers: function () {
      return this.s_persons
        .filter((person) => {
          return person.personId !== this.personData.personId;
        })
        .map((v) => v.phoneHome);
    },
    phoneHomeRules: function () {
      return {
        required: this.phoneHomeIsRequired,
        phone_number: true,
        unique_phone_home: this.phoneHomeIsRequired ? this.otherClientPhoneHomeNumbers : false,
      };
    },
    mainClientName: function () {
      return (
        this.s_persons.filter((person) => person?.cognitoUserId).map((v) => `${v.firstName} ${v.lastName}`)[0] ??
        'main person'
      );
    },
  },
};
</script>

<template>
  <div>
    <div class="row pb-2">
      <div class="col-md-12 text-uppercase">
        <h5>
          <b>
            Please provide your details including your FULL name (including Middle Name(s)) as it would appear on your
            Passport or Driving Licence.
          </b>
        </h5>
      </div>
    </div>

    <div class="row pb-2">
      <div class="col-md-3">
        <h5>
          <label for="about_you_title" class="mandatory"> Title: </label>
        </h5>
      </div>

      <div class="col-md-9">
        <ValidationProvider name="title" rules="required" v-slot="validationContext">
          <b-form-select
            v-model="personData.title"
            :options="titles"
            name="title"
            id="about_you_title"
            class="form-control bg-white"
            :state="getValidationState(validationContext)"
            aria-describedby="title-error"
          ></b-form-select>
          <b-form-invalid-feedback data-error-name="title-error"
            >{{ validationContext.errors[0] }}
          </b-form-invalid-feedback>
        </ValidationProvider>
      </div>
    </div>

    <div class="row pb-2">
      <div class="col-md-3">
        <h5>
          <label for="first-name" class="mandatory"> First Name: </label>
        </h5>
      </div>

      <div class="col-md-9">
        <ValidationProvider
          name="first name"
          :rules="{ required: true, person_name: 'first name' }"
          v-slot="validationContext"
        >
          <b-form-input
            v-model.trim="personData.firstName"
            id="first-name"
            class="form-control"
            :state="getValidationState(validationContext)"
          ></b-form-input>
          <b-form-invalid-feedback data-error-name="first-name-error"
            >{{ validationContext.errors[0] }}
          </b-form-invalid-feedback>
        </ValidationProvider>
      </div>
    </div>

    <div class="row pb-2">
      <div class="col-md-3">
        <h5>
          <label for="middle-name"> Middle Name(s): </label>
        </h5>
      </div>

      <div class="col-md-9">
        <ValidationProvider name="middle name" :rules="{ person_name: 'middle name' }" v-slot="validationContext">
          <b-form-input
            v-model.trim="personData.middleName"
            :disabled="personData.noMiddleNameConfirmed"
            id="middle-name"
            class="form-control"
            :state="getValidationState(validationContext)"
          ></b-form-input>
          <b-form-invalid-feedback data-error-name="middle-name-error"
            >{{ validationContext.errors[0] }}
          </b-form-invalid-feedback>
        </ValidationProvider>
      </div>
    </div>

    <div class="row pb-2" v-if="!middleNameSet">
      <div class="col-md-3"></div>
      <div class="col-md-9">
        <ValidationProvider
          name="middle name confirm"
          :rules="{ required: { allowFalse: false } }"
          v-slot="validationContext"
        >
          <b-form-checkbox
            v-model="personData.noMiddleNameConfirmed"
            type="checkbox"
            id="middle-name-confirm"
            :state="getValidationState(validationContext)"
            :aria-invalid="validationContext.errors[0] !== null"
            >Please confirm that you do not have a middle name.</b-form-checkbox
          >
        </ValidationProvider>
      </div>
    </div>

    <div class="row pb-2">
      <div class="col-md-3">
        <h5>
          <label for="last-name" class="mandatory"> Last Name: </label>
        </h5>
      </div>

      <div class="col-md-9">
        <ValidationProvider
          name="last name"
          :rules="{ required: true, person_name: 'last name' }"
          v-slot="validationContext"
        >
          <b-form-input
            v-model.trim="personData.lastName"
            id="last-name"
            class="form-control"
            :state="getValidationState(validationContext)"
          ></b-form-input>
          <b-form-invalid-feedback data-error-name="last-name-error"
            >{{ validationContext.errors[0] }}
          </b-form-invalid-feedback>
        </ValidationProvider>
      </div>
    </div>

    <div class="row pb-2">
      <div class="col-md-3">
        <h5>
          <label for="former-name"> Previous Name(s): </label>
        </h5>
      </div>

      <div class="col-md-9">
        <ValidationProvider name="previous name" rules="alpha_spaces" v-slot="validationContext">
          <b-form-input
            v-model="personData.previousName"
            id="former-name"
            class="form-control"
            :state="getValidationState(validationContext)"
          ></b-form-input>
          <b-form-invalid-feedback data-error-name="former-name-error"
            >{{ validationContext.errors[0] }}
          </b-form-invalid-feedback>
        </ValidationProvider>
      </div>
    </div>

    <div class="row pb-2">
      <div class="col-md-3">
        <h5>
          <label for="dob" class="mandatory"> Date of birth: </label>
        </h5>
      </div>

      <div class="col-md-9">
        <date-picker
          v-model="personData.dob"
          validationFieldName="DOB"
          minimumView="day"
          :required="true"
          id="person-dob"
        />
      </div>
    </div>

    <div class="row pb-2">
      <div class="col-md-3">
        <h5>
          <label for="gender" class="mandatory"> Gender: </label>
        </h5>
      </div>

      <div class="col-md-9">
        <ValidationProvider name="gender" rules="required" v-slot="validationContext">
          <b-form-select
            v-model="personData.gender"
            :options="genderValues"
            name="gender"
            id="gender"
            class="form-control bg-white"
            :state="getValidationState(validationContext)"
            aria-describedby="gender-error"
          ></b-form-select>
          <b-form-invalid-feedback data-error-name="gender-error"
            >{{ validationContext.errors[0] }}
          </b-form-invalid-feedback>
        </ValidationProvider>
      </div>
    </div>

    <div class="row pb-2">
      <div class="col-md-3">
        <h5>
          <label for="nationality" class="mandatory"> Nationality: </label>
        </h5>
      </div>

      <div class="col-md-9">
        <ValidationProvider name="nationality" rules="required|min:2" v-slot="validationContext">
          <b-form-select
            v-model="personData.nationality"
            :options="nationalities"
            name="nationality"
            id="nationality"
            class="form-control bg-white"
            :state="getValidationState(validationContext)"
            aria-describedby="nationality-error"
          >
            <template #first>
              <b-form-select-option :value="0" disabled>-- Please select an option --</b-form-select-option>
            </template>
          </b-form-select>
          <b-form-invalid-feedback data-error-name="nationality-error"
            >The nationality field must be set
          </b-form-invalid-feedback>
        </ValidationProvider>
      </div>
    </div>

    <div class="row pb-2" v-if="showRelationshipQuestion">
      <div class="col-md-3">
        <h5>
          <label class="mandatory"> Relationship to {{ mainClientName }}: </label>
        </h5>
      </div>

      <div class="col-md-9">
        <ValidationProvider name="relationship" rules="required" v-slot="validationContext">
          <b-form-select
            :options="relationships"
            :state="getValidationState(validationContext)"
            class="form-control bg-white"
            id="relationship"
            v-model="personData.relationship"
          ></b-form-select>
          <b-form-invalid-feedback data-error-name="relationship-error"
            >{{ validationContext.errors[0] }}
          </b-form-invalid-feedback>
        </ValidationProvider>
      </div>
    </div>

    <div class="row pb-2" v-if="personData.relationship === 'Other' && showRelationshipQuestion">
      <div class="col-md-3">
        <h5>
          <label class="mandatory"> Other Relationship: </label>
        </h5>
      </div>

      <div class="col-md-9">
        <ValidationProvider name="other relationship" rules="required|alpha_spaces" v-slot="validationContext">
          <b-form-input
            :state="getValidationState(validationContext)"
            class="form-control"
            id="relationship-other"
            v-model="personData.relationshipOther"
          ></b-form-input>
          <b-form-invalid-feedback data-error-name="relationship-other-error"
            >{{ validationContext.errors[0] }}
          </b-form-invalid-feedback>
        </ValidationProvider>
      </div>
    </div>

    <div class="row pb-2">
      <div class="col-md-3">
        <h5>
          <label class="mandatory"> Are you currently or have you ever been a politically exposed person (PEP)? </label>
        </h5>
        <div class="d-flex align-items-center justify-content-md-start pt-1 pb-1">
          <span class="text-info" @click="togglePepInfo(true)">
            <i class="icon-info22 mr-2 icon"></i>
            <span class="text-nowrap">More info</span>
          </span>
        </div>
      </div>

      <div class="col-md-9">
        <ValidationProvider name="politically exposed person" rules="required" v-slot="validationContext">
          <b-form-select
            v-model="personData.politicallyExposedPerson"
            :options="yesOrNo"
            name="politically-exposed-person"
            id="politically-exposed-person"
            class="form-control bg-white"
            :state="getValidationState(validationContext)"
            aria-describedby="politically-exposed-person-error"
          ></b-form-select>
          <b-form-invalid-feedback data-error-name="politically-exposed-person-error"
            >{{ validationContext.errors[0] }}
          </b-form-invalid-feedback>
        </ValidationProvider>
      </div>
    </div>

    <div class="row pb-2">
      <div class="col-md-3">
        <h5>
          <label> Correspondence Address (including Post Code): </label>
        </h5>
      </div>
    </div>

    <address-lookup
      class="pb-1"
      v-model="personData.correspondenceAddress"
      :addResidency="false"
      :validatePostcodeFormat="false"
    ></address-lookup>

    <div class="row pb-2">
      <div class="col-md-3">
        <h5>
          <label> Previous Addresses: </label>
        </h5>
      </div>

      <div class="col-md-6"></div>

      <div class="col-md-3 text-right">
        <b-button v-b-toggle.addPreviousAddress variant="success" v-if="!visibleForm">
          <span v-if="!visibleForm">Add Previous Address</span>
        </b-button>
      </div>
    </div>

    <b-collapse id="addPreviousAddress" class="w-100" v-model="visibleForm">
      <address-lookup
        v-if="visibleForm"
        v-model="currentPreviousAddress"
        :addResidency="true"
        ref="previousAddressForm"
        :disabledRanges="rangesOfPreviousAddresses"
      ></address-lookup>

      <div class="d-flex justify-content-end">
        <button class="btn btn-cancel mb-1 mr-1" @click="visibleForm = false"></button>
        <button class="btn btn-save mb-1" @click="addPreviousAddress"></button>
      </div>
    </b-collapse>
    <div v-if="previousAddresses" class="w-100">
      <div class="col-md-12 mb-1">
        <previous-address
          :ref="`prev${item.index}`"
          v-for="item in actualPreviousAddresses"
          :key="item.index"
          v-model="item.address"
          :index="item.index"
          @edit="editPreviousAddress(item.index)"
          @remove="removePreviousAddress(item.index)"
        ></previous-address>
      </div>
    </div>

    <div class="row pb-2">
      <div class="col-md-3">
        <h5>
          <label for="phone-mobile" :class="phoneMobileIsRequired ? 'mandatory' : ''"> Mobile Telephone: </label>
        </h5>
      </div>

      <div class="col-md-9">
        <ValidationProvider name="mobile number" :rules="phoneMobileRules" v-slot="validationContext">
          <b-form-input
            v-model="personData.phoneMobile"
            id="phone-mobile"
            class="form-control"
            :state="getValidationState(validationContext)"
            type="tel"
          ></b-form-input>
          <b-form-invalid-feedback data-error-name="phone-mobile-error"
            >{{ validationContext.errors[0] }}
          </b-form-invalid-feedback>
        </ValidationProvider>
      </div>
    </div>

    <div class="row pb-2">
      <div class="col-md-3">
        <h5>
          <label for="home-number" :class="phoneHomeIsRequired ? 'mandatory' : ''"> Home Telephone: </label>
        </h5>
      </div>

      <div class="col-md-9">
        <ValidationProvider name="home number" :rules="phoneHomeRules" v-slot="validationContext">
          <b-form-input
            v-model="personData.phoneHome"
            id="home-number"
            class="form-control"
            :state="getValidationState(validationContext)"
            type="tel"
          ></b-form-input>
          <b-form-invalid-feedback data-error-name="home-number-error"
            >{{ validationContext.errors[0] }}
          </b-form-invalid-feedback>
        </ValidationProvider>
      </div>
    </div>

    <div class="row pb-2">
      <div class="col-md-3">
        <h5>
          <label for="work-phone"> Work Telephone: </label>
        </h5>
      </div>

      <div class="col-md-9">
        <ValidationProvider name="work phone" rules="phone_number" v-slot="validationContext">
          <b-form-input
            v-model="personData.phoneWork"
            id="work-phone"
            class="form-control"
            :state="getValidationState(validationContext)"
            type="tel"
          ></b-form-input>
          <b-form-invalid-feedback data-error-name="work-phone-error"
            >{{ validationContext.errors[0] }}
          </b-form-invalid-feedback>
        </ValidationProvider>
      </div>
    </div>

    <div class="row pb-2">
      <div class="col-md-3">
        <h5>
          <label for="email" class="mandatory"> Email: </label>
        </h5>
      </div>

      <div class="col-md-9">
        <ValidationProvider name="email" :rules="emailRules" v-slot="validationContext" :debounce="500">
          <b-form-input
            v-model="personData.email"
            id="email"
            class="form-control"
            :state="getValidationState(validationContext)"
            type="email"
            :disabled="!!personData.cognitoUserId"
          ></b-form-input>
          <b-form-invalid-feedback data-error-name="email-error">
            {{ validationContext.errors[0] }}
          </b-form-invalid-feedback>
        </ValidationProvider>
        <small v-if="!!personData.cognitoUserId"
          >Please contact us if you need to change the primary client's email.</small
        >
      </div>
    </div>

    <div class="row pb-2">
      <div class="col-md-3">
        <h5>
          <label for="occupation" class="mandatory"> Occupation: </label>
        </h5>
      </div>
      <div class="col-md-9">
        <ValidationProvider name="occupation" rules="required|min:3" v-slot="validationContext">
          <b-form-input
            v-model="personData.occupation"
            id="occupation"
            class="form-control"
            :state="getValidationState(validationContext)"
          ></b-form-input>
          <b-form-invalid-feedback data-error-name="occupation-error"
            >{{ validationContext.errors[0] }}
          </b-form-invalid-feedback>
        </ValidationProvider>
      </div>
    </div>

    <div class="row pb-2">
      <div class="col-md-3">
        <h5>
          <label for="ni-number" class="mandatory"> National insurance No: </label>
        </h5>
      </div>

      <div class="col-md-9">
        <ValidationProvider name="NI number" rules="ni_number|required" v-slot="validationContext">
          <b-form-input
            v-model="personData.niNumber"
            id="ni-number"
            class="form-control"
            :state="getValidationState(validationContext)"
            @input="(e) => (personData.niNumber = e.toUpperCase())"
          ></b-form-input>
          <b-form-invalid-feedback data-error-name="ni-number-error"
            >{{ validationContext.errors[0] }}
          </b-form-invalid-feedback>
        </ValidationProvider>
      </div>
    </div>

    <div class="row pb-2">
      <div class="col-md-12">
        <div class="border-top my-3"></div>
        <h2>Bankruptcy</h2>

        <simple-question
          v-model="bankrupt"
          :dropdownOptions="yesOrNo"
          labelName="bankruptLabelName"
          title="Have you ever been made bankrupt?"
          validationFieldName="bankrupt"
        ></simple-question>
      </div>
    </div>

    <div class="row pb-2">
      <div class="col-md-12">
        <free-type-year-question
          v-if="bankrupt.answer"
          v-model="bankruptDate"
          title="If Yes, please give the date you were made bankrupt:"
          labelName="bankrupcyDateLabelName"
          :required="true"
          validationFieldName="bankruptcy date"
          minimumView="day"
        />
      </div>
    </div>

    <div class="row pb-2">
      <div class="col-md-12">
        <div class="border-top my-3"></div>
        <h2>Availability</h2>

        <h5>
          <label for="availability">
            Have you any plans to be away (e.g. on holiday) when you will not be contactable in the reasonably near
            future? If so, please provide details:
          </label>
        </h5>
      </div>
    </div>

    <ValidationProvider name="availability" rules="min:3" v-slot="{ errors }">
      <div>
        <textarea class="form-control" id="availability" rows="4" v-model="personData.availability"></textarea>
        <span class="form-text text-danger">{{ errors[0] }}</span>
      </div>
    </ValidationProvider>

    <div class="row pb-2">
      <div class="col-md-12">
        <div class="border-top my-3"></div>
        <h2>Higher Rates Of Stamp Duty Land Tax</h2>

        <h5 class="pb-2">
          From 1st April 2016, higher rates of Stamp Duty are payable on certain purchasers of additional residential
          properties. It is therefore very important that each purchaser identifies the total number of interests in
          residential property he or she will own immediately after completion of the proposed purchase.
        </h5>

        <h5 class="pb-2">
          You will see that the position is not straightforward due to the requirement that (for example) you take into
          account interests in residential property:-
        </h5>
        <ul>
          <li>
            <h5>Owned in any purchaser's sole name</h5>
          </li>
          <li>
            <h5>Owned anywhere in the world</h5>
          </li>
          <li>
            <h5>Owned jointly with anyone else (whether or not they are a party to the current purchase)</h5>
          </li>
          <li>
            <h5>
              Owned in the name of your spouse or civil partner (whether or not they are party to the current purchase)
            </h5>
          </li>
          <li>
            <h5>
              Owned or held in the names of your minor children or the minor children of your spouse or civil partner
              (whether or not they are party to the current purchase)
            </h5>
          </li>
          <li>
            <h5>Owned as a result of an inheritance</h5>
          </li>
          <li>
            <h5>Owned via a partnership</h5>
          </li>
          <li>
            <h5>Held as a trust or beneficiary of a trust</h5>
          </li>
          <li>
            <h5>Owned by a company</h5>
          </li>
        </ul>

        <h5 class="pb-2">
          You will see that the interest is taken into account if it has a value of more than £40,000.00.
        </h5>

        <h5 class="pb-2">
          You will see that the higher rate of Stamp Duty will not apply if you are replacing your only or main
          residence. Special rules apply if you have already disposed of your only or main residence or will be
          disposing of it in the future.
        </h5>
      </div>
    </div>

    <div class="row pb-2">
      <div class="col-md-9">
        <h5 class="mandatory">
          Taking in to account the above and government guidance referred to, please confirm how many interests in
          residential property you will own (including this purchase) immediately after completion:
        </h5>
      </div>

      <div class="col-md-3">
        <ValidationProvider
          name="residential property interests owned"
          rules="required|numeric"
          v-slot="validationContext"
        >
          <b-form-input
            v-model.number="personData.propertyInterestsOwned"
            id="property-interests-owned"
            class="form-control"
            min="0"
            :state="getValidationState(validationContext)"
            type="number"
          ></b-form-input>
          <b-form-invalid-feedback data-error-name="property-interests-owned-error"
            >{{ validationContext.errors[0] }}
          </b-form-invalid-feedback>
        </ValidationProvider>
      </div>
    </div>

    <div class="row pb-2">
      <div class="col-lg-8"></div>

      <div class="col col-lg-2">
        <button class="btn btn-save btn-block" @click="triggerUpdate"></button>
      </div>

      <div class="col col-lg-2">
        <button class="btn btn-cancel btn-block" @click="$emit('cancelUpdate')"></button>
      </div>
    </div>
    <modal v-if="this.showDuplicatedClientModal" closeButtonText="Close" @proceed="closeDuplicatedClient()">
      <div class="text-center">
        <i class="icon-info3 icon-2x text-info border-info border-3 rounded-round p-3 mb-3 mt-1"></i>
        <h3>Duplicated Client</h3>
        <p>
          Client already exists, First Name, Last Name and Date of Birth are duplicated. Please double check that the
          details entered are related to a separate client involved in this case.
        </p>
      </div>
    </modal>

    <modal
      v-if="showPepInfo"
      :show-buttons="true"
      closeButtonText="Close"
      :fullModalWidth="false"
      @proceed="togglePepInfo"
    >
      <politically-exposed-person-info />
    </modal>
  </div>
</template>

<style scoped></style>
