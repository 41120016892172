<script>
import CurrencyInput from '@/components/forms/inputGroups/CurrencyInput';
import LenderOptions from '@/mixins/LenderOptions';

const debtDefaultData = {
  existing_mortgage_lender: '',
  existing_mortgage_number: '',
  amount_owing: 0,
  current_provider: null,
};

const yesOrNo = [
  { text: 'Yes', value: true },
  { text: 'No', value: false },
];

export default {
  components: { CurrencyInput },
  name: 'Debt',
  mixins: [LenderOptions],
  props: {
    value: {
      type: Object,
    },
  },
  data() {
    return {
      saving: false,
      yesOrNo,
      debt: {
        ...debtDefaultData,
        ...this.value,
      },
    };
  },
  methods: {
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },
    async save() {
      const isValid = await this.$refs.observer.validate();
      if (!isValid) {
        return false;
      }
      if (this.value) {
        this.$emit('save-existing-debt', this.debt);
      } else {
        this.$emit('save-new-debt', this.debt);
      }
    },
    cancel() {
      this.$emit('cancel');
    },
    setNewLenderName(value) {
      this.debt.existing_mortgage_lender = this.getLenderNameFromOption(value);
    },
  },
  computed: {
    currentLenderNameOption: {
      get() {
        return this.getLenderNameOptionFromName(this.debt.existing_mortgage_lender);
      },
    },
    showCurrentLenderNameFreeTextInput: {
      get() {
        return this.currentLenderNameOption && this.currentLenderNameOption.id === 0;
      },
    },
    newLenderNameOption: {
      get() {
        return this.getLenderNameOptionFromName(this.debt.existing_mortgage_lender);
      },
    },
  },
};
</script>

<template>
  <ValidationObserver ref="observer" slim>
    <!-- New lender section -->
    <div class="row pb-2">
      <div class="col-md-3">
        <h5>
          <label class="mandatory"> Lender: </label>
        </h5>
      </div>

      <div class="col-md-9">
        <validation-provider name="existing mortgage lender" rules="required" v-slot="validationContext">
          <v-select
            :value="newLenderNameOption"
            @input="setNewLenderName"
            :options="lenders"
            id="existing mortgage lender"
            class="bg-white"
            v-bind:class="{
              'is-valid': !validationContext.errors.length,
              'is-invalid': validationContext.errors.length,
            }"
            :state="getValidationState(validationContext)"
            ><span slot="no-options">{{ noMatchingLenderMessage }}</span></v-select
          >
          <div
            v-if="validationContext.errors"
            v-bind:class="{ 'invalid-feedback d-block': validationContext.errors }"
            data-error-name="existing-mortgage-lender-error"
          >
            {{ validationContext.errors[0] }}
          </div>
        </validation-provider>
      </div>
    </div>

    <div v-if="showCurrentLenderNameFreeTextInput">
      <div class="row pb-2">
        <div class="col-md-3">
          <h5>
            <label class="mandatory"> Lender (Other) </label>
          </h5>
        </div>
        <div class="col-md-9">
          <ValidationProvider name="current lender name" rules="required" v-slot="validationContext">
            <b-form-input
              v-model="debt.existing_mortgage_lender"
              name="currentLenderName"
              debounce="500"
              class="form-control"
              :state="getValidationState(validationContext)"
            ></b-form-input>
            <b-form-invalid-feedback data-error-name="current-lender-name-other-error"
              >{{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </ValidationProvider>
        </div>
      </div>
    </div>
    <!-- End new lender section -->

    <div class="row pb-2">
      <div class="col-md-3">
        <h5>
          <label class="mandatory"> Amount of Loan: </label>
        </h5>
      </div>

      <div class="col-md-9">
        <currency-input
          v-model.number="debt.amount_owing"
          :required="true"
          validationFieldName="amount owing"
          id="amount-owing"
        ></currency-input>
      </div>
    </div>

    <div class="row pb-2">
      <div class="col-md-3">
        <h5>
          <label class="mandatory"> Is this a current mortgage provider? </label>
        </h5>
      </div>

      <div class="col-md-9">
        <ValidationProvider name="current mortgage provider" rules="required" v-slot="validationContext">
          <b-form-select
            v-model="debt.current_provider"
            :options="yesOrNo"
            id="current-provider"
            class="form-control bg-white"
            :state="getValidationState(validationContext)"
          ></b-form-select>
          <b-form-invalid-feedback data-error-name="current-provider-error"
            >{{ validationContext.errors[0] }}
          </b-form-invalid-feedback>
        </ValidationProvider>
      </div>
    </div>

    <div class="row pb-2">
      <div class="col-md-3">
        <h5>
          <label> Existing Mortgage Number (if known): </label>
        </h5>
      </div>

      <div class="col-md-9">
        <validation-provider name="existing mortgage number" :rules="{ required: false }" v-slot="validationContext">
          <b-form-input
            v-model="debt.existing_mortgage_number"
            id="existing_mortgage_number"
            :state="getValidationState(validationContext)"
          ></b-form-input>
          <b-form-invalid-feedback data-error-name="existing-mortgage-number-error"
            >{{ validationContext.errors[0] }}
          </b-form-invalid-feedback>
        </validation-provider>
      </div>
    </div>

    <div class="row pb-1 justify-content-end">
      <div class="col-md-3">
        <button @click="cancel" class="btn btn-cancel btn-block mb-1" />
      </div>
      <div class="col-md-3">
        <button id="save_debt" @click="save" class="btn btn-save btn-block" />
      </div>
    </div>
  </ValidationObserver>
</template>
