<script>
import CurrencyInput from '@/components/forms/inputGroups/CurrencyInput';
import AddressLookup from '@/components/forms/inputGroups/addressLookup/AddressLookup';

const yesOrNo = {
  true: 'Yes',
  false: 'No',
};

const sourceOfFundsValues = [
  { value: null, text: 'Please choose', disabled: true },
  { value: 'savings', text: 'Savings' },
  { value: 'gift', text: 'Gift' },
  { value: 'inheritance', text: 'Inheritance' },
  { value: 'investment', text: 'Investment' },
  { value: 'help to buy', text: 'Help to Buy' },
  { value: 'other', text: 'Other' },
];

const fundsDefaultData = {
  source_of_funds: null,
  fund_value: null,
  first_name: '',
  middle_name: '',
  last_name: '',
  email: '',
  phone: '',
  correspondence_address: {
    organisation_name: '',
    premise: '',
    thoroughfare: '',
    dependent_locality: '',
    locality: '',
    subAdministrativeArea: '',
    administrative_area: '',
    postal_code: '',
  },
  fund_details: '',
};

export default {
  components: { CurrencyInput, AddressLookup },
  name: 'Funds',
  props: {
    value: {
      type: Object,
    },
  },
  data() {
    return {
      saving: false,
      yesOrNo,
      sourceOfFundsValues,
      fund: {
        ...fundsDefaultData,
        ...this.value,
      },
    };
  },
  methods: {
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },
    async save() {
      const isValid = await this.$refs.observer.validate();
      if (!isValid) {
        return false;
      }
      if (this.value) {
        this.$emit('save-existing-fund', this.fund);
      } else {
        this.$emit('save-new-fund', this.fund);
      }
    },
    cancel() {
      this.$emit('cancel');
    },
  },
};
</script>

<template>
  <ValidationObserver ref="observer" slim>
    <div class="row pb-2">
      <div class="col-md-3">
        <h5>
          <label class="mandatory"> Source of Funds: </label>
        </h5>
      </div>
      <div class="col-md-9">
        <ValidationProvider name="property type" rules="required" v-slot="validationContext">
          <b-form-select
            v-model="fund.source_of_funds"
            :options="sourceOfFundsValues"
            id="property-type"
            class="form-control bg-white"
            :state="getValidationState(validationContext)"
          ></b-form-select>
          <b-form-invalid-feedback data-error-name="property-type-error"
            >{{ validationContext.errors[0] }}
          </b-form-invalid-feedback>
        </ValidationProvider>
      </div>
    </div>

    <div class="row pb-2">
      <div class="col-md-3">
        <h5>
          <label class="mandatory"> Amount: </label>
        </h5>
      </div>

      <div class="col-md-9">
        <currency-input
          v-model="fund.fund_value"
          validationFieldName="sale price"
          id="sale-price"
          :required="true"
        ></currency-input>
      </div>
    </div>

    <div class="row pb-2">
      <div class="col-md-3">
        <h5>Additional Details:</h5>
      </div>

      <div class="col-md-9">
        <validation-provider
          name="planning permission not required description"
          rules="min:3"
          v-slot="validationContext"
        >
          <b-form-textarea
            v-model="fund.fund_details"
            id="planning-permissions-not-required-description"
            rows="4"
            :state="getValidationState(validationContext)"
          ></b-form-textarea>
          <b-form-invalid-feedback v-if="validationContext.errors[0]" data-error-name="textarea-error"
            >{{ validationContext.errors[0] }}
          </b-form-invalid-feedback>
        </validation-provider>
      </div>
    </div>

    <!-- Ask about personal details when source of founds is a gift -->
    <div v-if="fund.source_of_funds === 'gift'">
      <div class="row pb-2">
        <div class="col-md-12">
          <h5>
            <label> If Gift, please specify source: </label>
          </h5>
        </div>
      </div>

      <div class="row pb-2">
        <div class="col-md-3">
          <h5>
            <label class="mandatory"> First Name: </label>
          </h5>
        </div>

        <div class="col-md-9">
          <ValidationProvider name="first name" rules="alpha_spaces|required" v-slot="validationContext">
            <b-form-input
              v-model="fund.first_name"
              id="first-name"
              class="form-control"
              :state="getValidationState(validationContext)"
            ></b-form-input>
            <b-form-invalid-feedback data-error-name="first-name-error"
              >{{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </ValidationProvider>
        </div>
      </div>

      <div class="row pb-2">
        <div class="col-md-3">
          <h5>
            <label> Middle Name(s): </label>
          </h5>
        </div>

        <div class="col-md-9">
          <ValidationProvider name="middle name" rules="alpha_spaces" v-slot="validationContext">
            <b-form-input
              v-model="fund.middle_name"
              id="middle-name"
              class="form-control"
              :state="getValidationState(validationContext)"
            ></b-form-input>
            <b-form-invalid-feedback data-error-name="middle-name-error"
              >{{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </ValidationProvider>
        </div>
      </div>

      <div class="row pb-2">
        <div class="col-md-3">
          <h5>
            <label class="mandatory"> Last Name: </label>
          </h5>
        </div>

        <div class="col-md-9">
          <ValidationProvider name="last name" rules="alpha_spaces|required" v-slot="validationContext">
            <b-form-input
              v-model="fund.last_name"
              id="last-name"
              class="form-control"
              :state="getValidationState(validationContext)"
            ></b-form-input>
            <b-form-invalid-feedback data-error-name="last-name-error"
              >{{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </ValidationProvider>
        </div>
      </div>

      <div class="row pb-2">
        <div class="col-md-12">
          <h5>
            <label> Correspondence Address (including Post Code): </label>
          </h5>
        </div>
      </div>

      <div class="row pb-2">
        <div class="col-md-12">
          <address-lookup
            :addResidency="false"
            :requiresValidation="false"
            v-model="fund.correspondence_address"
          ></address-lookup>
        </div>
      </div>

      <div class="row pb-2">
        <div class="col-md-3">
          <h5>
            <label class="line-height-base mandatory"> Telephone Number: </label>
          </h5>
        </div>

        <div class="col-md-9">
          <ValidationProvider name="telephone number" rules="phone_number|required" v-slot="validationContext">
            <b-form-input
              v-model="fund.phone"
              id="telephone-number"
              class="form-control"
              :state="getValidationState(validationContext)"
            ></b-form-input>
            <b-form-invalid-feedback data-error-name="telephone-number-error"
              >{{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </ValidationProvider>
        </div>
      </div>

      <div class="row pb-2">
        <div class="col-md-3">
          <h5>
            <label class="line-height-base mandatory"> Email: </label>
          </h5>
        </div>

        <div class="col-md-9">
          <ValidationProvider name="email" rules="email|required" v-slot="validationContext">
            <b-form-input
              v-model="fund.email"
              id="email"
              class="form-control"
              :state="getValidationState(validationContext)"
            ></b-form-input>
            <b-form-invalid-feedback data-error-name="email-error"
              >{{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </ValidationProvider>
        </div>
      </div>
    </div>
    <!-- End personal details question -->

    <div class="row pb-1 justify-content-end">
      <div class="col-md-3">
        <button @click="cancel" class="btn btn-cancel btn-block mb-1" />
      </div>
      <div class="col-md-3">
        <button id="save_debt" @click="save" class="btn btn-save btn-block" />
      </div>
    </div>
  </ValidationObserver>
</template>
