<script>
import AddressLookup from '@/components/forms/inputGroups/addressLookup/AddressLookup';

const yesOrNo = [
  { value: null, text: 'Please choose', disabled: true },
  { value: true, text: 'Yes' },
  { value: false, text: 'No' },
];

const caseDefaultData = {
  related_property_address: {
    organisation_name: '',
    premise: null,
    thoroughfare: null,
    dependent_locality: null,
    locality: null,
    subAdministrativeArea: null,
    administrative_area: null,
    postal_code: null,
  },
  related_case_firm_id: '',
  selling_surplus: null,
};

export default {
  name: 'RelatedCase',
  components: { AddressLookup },
  props: {
    value: {
      type: Object,
    },
  },
  data() {
    return {
      saving: false,
      yesOrNo,
      relatedCase: {
        ...caseDefaultData,
        ...this.value,
      },
    };
  },
  methods: {
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },
    async save() {
      const isValid = await this.$refs.observer.validate();
      if (!isValid) {
        return false;
      }

      if (this.value) {
        this.$emit('save-existing-related-case', this.relatedCase);
      } else {
        this.$emit('save-new-related-case', this.relatedCase);
      }
    },
    cancel() {
      this.$emit('cancel');
    },
  },
};
</script>

<template>
  <ValidationObserver ref="observer" slim>
    <div class="row pb-1">
      <div class="col-md-3">
        <h5 class="line-height-base">
          <label class="mandatory">Type Of Case:</label>
        </h5>
      </div>

      <div class="col-md-9">
        <validation-provider v-slot="validationContext" :rules="{ required: true }" name="related case type">
          <b-form-group label-for="type" label-size="lg">
            <b-form-select
              id="relatedCaseType"
              v-model="relatedCase.related_case_type"
              :options="[{ value: '', text: 'Please choose', disabled: true }, 'remortgage', 'sale']"
              :state="getValidationState(validationContext)"
              class="form-control bg-white text-capitalize"
              ref="answer"
            ></b-form-select>
            <b-form-invalid-feedback data-error-name="related-case-type-error"
              >{{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>
      </div>
    </div>

    <div class="row pb-1">
      <div class="col-md-3">
        <h5>
          <label> Related Property Address: </label>
        </h5>
      </div>
    </div>

    <address-lookup
      id="addressLockup"
      :addResidency="false"
      v-model="relatedCase.related_property_address"
    ></address-lookup>

    <div class="row pb-1">
      <div class="col-md-3">
        <h5 class="line-height-base">
          <label> Which Solicitor is acting on your behalf (if known)? </label>
        </h5>
      </div>

      <div class="col-md-9">
        <validation-provider name="related case firm" :rules="{ required: false }" v-slot="validationContext" slim>
          <b-form-group label-size="lg" label-for="lender">
            <b-form-input
              v-model="relatedCase.related_case_firm_id"
              id="relatedCaseFirm"
              :state="getValidationState(validationContext)"
            ></b-form-input>
            <b-form-invalid-feedback data-error-name="related-case-firm-error"
              >{{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>
      </div>
    </div>

    <div class="row pb-2">
      <div class="col-md-9">
        <h5>
          <label class="mandatory" for="selling-surplus">Are you selling for more than you are purchasing?</label>
        </h5>
      </div>

      <div class="col-md-3">
        <ValidationProvider name="selling for more than you are purchasing" rules="required" v-slot="validationContext">
          <b-form-select
            v-model="relatedCase.selling_surplus"
            :options="yesOrNo"
            id="selling-surplus"
            class="form-control bg-white"
            :state="getValidationState(validationContext)"
          ></b-form-select>
          <b-form-invalid-feedback data-error-name="selling-surplus-error"
            >{{ validationContext.errors[0] }}
          </b-form-invalid-feedback>
        </ValidationProvider>
      </div>
    </div>

    <div class="row pb-1 justify-content-end">
      <div class="col-md-3">
        <button @click="cancel" class="btn btn-cancel btn-block mb-1" />
      </div>
      <div class="col-md-3">
        <button id="save_related_case" @click="save" class="btn btn-save btn-block" />
      </div>
    </div>
  </ValidationObserver>
</template>
