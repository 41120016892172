<script>
import { mapMutations, mapState } from 'vuex';
import * as mutations from '@/store/purchase/mutations';
import patchPurchase from '@/api/purchases/patchPurchase';
import RelatedCase from '@/components/forms/purchaseClientQuestionnaire/relatedCases/RelatedCase';
import RelatedCaseList from '@/components/forms/purchaseClientQuestionnaire/relatedCases/RelatedCaseList';

export default {
  name: 'RelatedSaleOrRemortgage',
  components: { RelatedCaseList, RelatedCase },
  computed: {
    ...mapState({
      s_purchase: (state) => state.purchase,
    }),
    relatedCases: {
      get() {
        return this.s_purchase.relatedCases;
      },
      set(value) {
        this[`${mutations.PURCHASE_PREFIX}${mutations.PURCHASE_SET_RELATED_CASES}`](value);
      },
    },
  },
  data() {
    return {
      showForm: false,
      currentEditedCase: null,
    };
  },
  methods: {
    ...mapMutations([
      `${mutations.PURCHASE_PREFIX}${mutations.PURCHASE_SET_RELATED_CASES}`,
      `${mutations.PURCHASE_PREFIX}${mutations.PURCHASE_ADD_NEW_RELATED_CASE}`,
    ]),
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },
    showDebtForm() {
      this.showForm = true;
      this.currentEditedCase = null;
    },
    showList() {
      this.showForm = false;
    },
    saveNewDebt(event) {
      this[`${mutations.PURCHASE_PREFIX}${mutations.PURCHASE_ADD_NEW_RELATED_CASE}`](event);
      this.showList();
    },
    editCase(relatedCaseIndex) {
      this.currentEditedCase = this.relatedCases[relatedCaseIndex];

      this.showForm = true;
    },
    saveExistingCase(debt) {
      const indexForRelatedCaseToBeUpdated = this.relatedCases.indexOf(this.currentEditedCase);

      this.relatedCases[indexForRelatedCaseToBeUpdated] = debt;
      this.currentEditedCase = debt;

      this.showList();
    },
    async saveToApi() {
      const isValid = await this.$refs.observer.validate();
      if (!isValid || this.showForm) {
        this.$el.querySelector('[aria-invalid=true]').focus();
        return false; // If the return result is false, tab switch is restricted
      }
      this.saving = true;
      await patchPurchase({
        id: this.s_purchase.purchaseId,
        relatedCases: this.s_purchase.relatedCases,
      });
      this.saving = false;

      return true;
    },
  },
};
</script>

<template>
  <validation-observer ref="observer">
    <h2>Related Sale or Remortgage</h2>

    <div class="row pb-1">
      <div class="col-md-9">
        <h5>
          Do you have a related Sale or Remortgage? If yes, please click the add button to provide more information:
        </h5>
      </div>

      <div class="col-md-3 text-center text-md-right text-lg-right">
        <button class="btn btn-add" @click="showDebtForm" v-if="!showForm"></button>
      </div>
    </div>

    <div class="border-top my-3"></div>

    <div>
      <related-case
        v-if="showForm"
        @cancel="showList()"
        @save-new-related-case="saveNewDebt"
        @save-existing-related-case="saveExistingCase"
        v-model="currentEditedCase"
      >
      </related-case>

      <related-case-list
        v-else-if="!showForm && relatedCases.length !== 0"
        v-model="relatedCases"
        @related-case-edit="editCase"
      >
      </related-case-list>

      <p class="text-muted" v-else>No related sale or remortgage have been added.</p>
    </div>

    <div class="border-top my-3"></div>
  </validation-observer>
</template>
