<script>
// @ is an alias to /src
import { mapActions, mapState } from 'vuex';
import FormWrapper from '@/components/FormWrapper';
import Modal from '@/components/Modal';
import { FormWizard, TabContent, WizardButton } from 'vue-form-wizard';
import 'vue-form-wizard/dist/vue-form-wizard.min.css';
import AboutYou from '@/components/forms/purchaseClientQuestionnaire/PurchaseAboutYou';
import AboutYourPurchase from '@/components/forms/purchaseClientQuestionnaire/AboutYourPurchase';
import SellerEstateAgent from '@/components/forms/purchaseClientQuestionnaire/SellerEstateAgent';
import RelatedSaleOrRemortgage from '@/components/forms/purchaseClientQuestionnaire/RelatedSaleOrRemortgage';
import Mortgages from '@/components/forms/purchaseClientQuestionnaire/Mortgages';
import OtherSourceOfFunds from '@/components/forms/purchaseClientQuestionnaire/OtherSourceOfFunds';
import Occupiers from '@/components/forms/purchaseClientQuestionnaire/OccupiersQuestionnaire';
import SummaryPage from '@/components/forms/SummaryPage';
import PdfPreview from '@/components/forms/PurchasePDFpreview';
import postTrackingEvent from '@/api/trackingEvents/postTrackingEvents';
import patchPurchaseQuestionnaireSubmitted from '@/api/purchases/patchPurchaseQuestionnaireSubmitted';
import SaveProgressInfo from '@/components/messaging/SaveProgressInfo';
import PurchaseReviewPage from '@/components/forms/purchaseClientQuestionnaire/PurchaseReviewPage';
import Other from '@/components/forms/purchaseClientQuestionnaire/OtherQuestionnaire';
import FormCompletion from '@/mixins/FormCompletion';

export default {
  name: 'PurchaseClientQuestionnaire',
  components: {
    FormWrapper,
    FormWizard,
    TabContent,
    WizardButton,
    Modal,
    AboutYou,
    AboutYourPurchase,
    SellerEstateAgent,
    RelatedSaleOrRemortgage,
    Mortgages,
    OtherSourceOfFunds,
    Occupiers,
    SummaryPage,
    PdfPreview,
    SaveProgressInfo,
    PurchaseReviewPage,
    Other,
  },
  mixins: [FormCompletion],
  data() {
    return {
      disabled: false,
      clientQuestionnaireForm: 0,
      showSubmitModal: false,
      wizardLoading: false,
      hideWizardNextButton: false,
      avoidForceRerender: false,
    };
  },
  props: ['entityType', 'entityId'],
  methods: {
    ...mapActions('purchase', ['addTrackingEvent', 'setEventToHandle']),
    setLoading(value) {
      this.wizardLoading = value;
    },
    forceRerender() {
      this.clientQuestionnaireForm++;
    },
    validatePerson: function () {
      return this.$refs.aboutYou.validatePersonsPayload();
    },
    validateOther: function () {
      return this.$refs.other.saveToApi();
    },
    savePurchase: function () {
      return this.$refs.aboutYourPurchase.saveToApi();
    },
    saveEstateAgent: function () {
      return this.$refs.sellerEstateAgent.saveToApi();
    },
    saveRelatedCases: function () {
      return this.$refs.relatedCases.saveToApi();
    },
    saveDebts: function () {
      return this.$refs.debts.saveToApi();
    },
    saveFunds: function () {
      return this.$refs.funds.saveToApi();
    },
    validateOccupiers: function () {
      return this.$refs.occupiers.validatePage();
    },
    validateReviewConfirmation: function () {
      return this.$refs.reviewPage.validateConfirmation();
    },
    showPdfPreview: function (downloadFile = false) {
      return this.$refs.pdfPreview.getPdf(downloadFile, 'purchase-questionnaire-form-');
    },
    saveAndRedirect: async function () {
      this.navigate('ViewEntity', { entityType: this.entityType, entityId: this.entityId });
    },
    saveAndShowModalBeforeSubmit: async function () {
      this.showSubmitModal = true;
    },
    async submitForm(value) {
      this.showSubmitModal = false;
      if (value) {
        this.disabled = true;

        let metadata = '';
        let eventSlug = 'completed_questionnaire';
        let trackingEvent = {
          product_slug: 'purchase',
          entity_id: this.entityId,
          event_slug: eventSlug,
          metadata: metadata,
        };

        await Promise.all([
          postTrackingEvent(trackingEvent),
          patchPurchaseQuestionnaireSubmitted({
            id: this.entityId,
          }),
        ])
          .then(() => {
            this.addTrackingEvent(trackingEvent);
            this.$gtag.event('Completed Milestone', {
              event_category: 'Task',
              event_label: 'Completed Purchase Questionnaire',
            });

            this.setEventToHandle('completed_questionnaire');

            this.navigate('ViewEntity', {
              entityType: this.entityType,
              entityId: this.entityId,
            });
          })
          .catch((err) => console.debug(err));

        this.disabled = false;
      }
    },
    scrollToTop() {
      if (this.$router.currentRoute.name == 'ViewEntity') {
        this.$scrollTo('.page-header');
      }
    },
    allowForceRerender(value) {
      this.avoidForceRerender = value;
    },
    personAddEditMode(val) {
      // Switch of wizard next button when user is in Edit or Add person mode
      this.hideWizardNextButton = val;
    },
    isSortLegal() {
      return this.s_supplier === 'Sort Legal';
    },
  },
  watch: {
    loading: function () {
      if (!this.avoidForceRerender) {
        this.forceRerender();
      }
    },
  },
  computed: mapState({
    loading: (state) => state.loading,
    debtsLoaded: (state) => state.purchase.loaded,
    s_supplier: (state) => state?.purchase?.config?.supplier,
  }),
};
</script>

<template>
  <form-wrapper :entityType="entityType" :entityId="entityId" class="mb-5">
    <div class="home" slot="form-content">
      <div :key="clientQuestionnaireForm">
        <form-wizard
          title=""
          subtitle=""
          validatePage
          :color="$t('site.formColour')"
          errorColor="#F44336"
          @on-loading="setLoading"
          @on-change="scrollToTop"
          stepSize="xs"
        >
          <tab-content title="About You" :before-change="validatePerson">
            <about-you @personAddEditMode="personAddEditMode" ref="aboutYou"></about-you>
            <save-progress-info />
          </tab-content>

          <tab-content title="About Your Purchase" :before-change="savePurchase">
            <about-your-purchase ref="aboutYourPurchase"></about-your-purchase>
          </tab-content>

          <tab-content title="Seller Estate Agent" :before-change="saveEstateAgent">
            <seller-estate-agent ref="sellerEstateAgent"></seller-estate-agent>
          </tab-content>

          <tab-content title="Related Sale or Remortgage" :before-change="saveRelatedCases">
            <related-sale-or-remortgage ref="relatedCases"></related-sale-or-remortgage>
          </tab-content>

          <tab-content title="Mortgages" :before-change="saveDebts">
            <mortgages ref="debts"></mortgages>
          </tab-content>

          <tab-content title="Source of Funds" :before-change="saveFunds">
            <other-source-of-funds ref="funds"></other-source-of-funds>
          </tab-content>

          <tab-content title="Occupiers" :before-change="validateOccupiers">
            <occupiers ref="occupiers"></occupiers>
          </tab-content>

          <tab-content v-if="this.isSortLegal()" title="Additional Information" :before-change="validateOther">
            <other ref="other"></other>
          </tab-content>

          <tab-content title="Review" :before-change="validateReviewConfirmation">
            <purchase-review-page ref="reviewPage"></purchase-review-page>
          </tab-content>

          <tab-content title="Summary">
            <summary-page
              ref="summary"
              :disabled="disabled"
              :entityType="entityType"
              @preview="showPdfPreview"
              @download="showPdfPreview(true)"
              @save="saveAndRedirect"
              @submit="saveAndShowModalBeforeSubmit"
            ></summary-page>
          </tab-content>

          <!-- Customised Buttons -->
          <template slot="footer" slot-scope="props">
            <div class="wizard-footer-left">
              <wizard-button
                v-if="props.activeTabIndex > 0"
                class="w-100 w-sm-auto bg-light border-dashed text-primary"
                @click.native="props.prevTab()"
                :style="props.fillButtonStyle"
              >
                <i class="icon-arrow-left5"></i> Previous
              </wizard-button>
            </div>
            <div class="wizard-footer-right">
              <div v-if="!props.isLastStep">
                <div v-if="!hideWizardNextButton">
                  <wizard-button
                    @click.native="props.nextTab()"
                    class="wizard-footer-right w-100 w-sm-auto"
                    :style="props.fillButtonStyle"
                    :disabled="wizardLoading"
                  >
                    Save and Continue
                    <i v-if="wizardLoading" class="icon-spinner11 spinner"></i>
                    <i v-else class="icon-arrow-right5"></i>
                  </wizard-button>
                </div>
              </div>
              <div v-else>
                <pdf-preview
                  form-name="PurchaseCQ"
                  ref="pdfPreview"
                  :entityType="entityType"
                  :entityId="entityId"
                  @allowForceRerender="allowForceRerender"
                ></pdf-preview>
                <modal
                  v-if="showSubmitModal"
                  closeButtonText="Close"
                  proceedButtonText="Save And Submit"
                  @proceed="submitForm"
                  >Be aware that once you "Save And Submit", the form will be locked for any changes. Do you want submit
                  this form?
                </modal>
              </div>
            </div>
          </template>
        </form-wizard>
      </div>
    </div>
  </form-wrapper>
</template>

<style lang="scss" />
