<script>
import { mapMutations, mapState } from 'vuex';
import * as mutations from '@/store/purchase/mutations';
import Funds from '@/components/forms/purchaseClientQuestionnaire/funds/Funds';
import FundsList from '@/components/forms/purchaseClientQuestionnaire/funds/FundsList';
import patchPurchase from '@/api/purchases/patchPurchase';

const yesOrNo = {
  true: 'Yes',
  false: 'No',
};

export default {
  name: 'OtherSourceOfFunds',
  components: { Funds, FundsList },
  computed: {
    ...mapState({
      s_purchase: (state) => state.purchase,
    }),
    sourceOfFunds: {
      get() {
        return this.s_purchase.sourceOfFunds;
      },
      set(value) {
        this[`${mutations.PURCHASE_PREFIX}${mutations.PURCHASE_SET_SOURCE_OF_FUNDS}`](value);
      },
    },
  },
  data() {
    return {
      showForm: false,
      currentEditedFund: null,
      yesOrNo,
    };
  },
  methods: {
    ...mapMutations([
      `${mutations.PURCHASE_PREFIX}${mutations.PURCHASE_SET_SOURCE_OF_FUNDS}`,
      `${mutations.PURCHASE_PREFIX}${mutations.PURCHASE_ADD_SOURCE_OF_FUNDS}`,
    ]),
    showFundForm() {
      this.showForm = true;
      this.currentEditedFund = null;
    },
    showList() {
      this.showForm = false;
    },
    saveNewFund(event) {
      this[`${mutations.PURCHASE_PREFIX}${mutations.PURCHASE_ADD_SOURCE_OF_FUNDS}`](event);
      this.showList();
    },
    editFund(fundIndexToBeEdited) {
      this.currentEditedFund = this.sourceOfFunds[fundIndexToBeEdited];

      this.showForm = true;
    },
    saveExistingFund(fund) {
      const indexForFundToBeUpdated = this.sourceOfFunds.indexOf(this.currentEditedFund);

      this.sourceOfFunds[indexForFundToBeUpdated] = fund;
      this.currentEditedFund = fund;

      this.showList();
    },
    async saveToApi() {
      const isValid = await this.$refs.observer.validate();
      if (!isValid || this.showForm) {
        this.$el.querySelector('[aria-invalid=true]').focus();
        return false; // If the return result is false, tab switch is restricted
      }
      this.saving = true;

      await patchPurchase({
        id: this.s_purchase.purchaseId,
        sourceOfFunds: this.s_purchase.sourceOfFunds,
      });
      this.saving = false;

      return true;
    },
  },
};
</script>

<template>
  <div>
    <h2>Source of Funds</h2>

    <div class="row pb-2">
      <div class="col-md-12">
        <h5>
          We are under a legal obligation to verify the source of the money that is being used to fund the transaction
          and also how that money was built up. Please confirm where the money you are using is currently being held, in
          whose name and also provide supporting evidence as per the examples set out below.
        </h5>
      </div>
    </div>

    <div class="row pb-2">
      <div class="col-md-12">
        <h5>
          <strong>
            Please note that the earlier we are able to verify your source of funds better. This part of the transaction
            can lead to delays further down the line if the information is not provided early so that we can identify
            any further documentation that may be needed.
          </strong>
        </h5>
      </div>
    </div>

    <div class="border-top my-3"></div>

    <div class="row pb-1">
      <div class="col-md-9">
        <h5>
          Do you have a deposit or additional source of funds contributing to the purchase of this property? If yes,
          please click the add button to provide more information:
        </h5>
      </div>

      <div class="col-md-3 d-flex justify-content-end">
        <button class="btn btn-add" @click="showFundForm" v-if="!showForm"></button>
      </div>
    </div>

    <div class="border-top my-3"></div>

    <validation-observer tag="div" ref="observer">
      <funds
        v-if="showForm"
        @cancel="showList()"
        @save-new-fund="saveNewFund"
        @save-existing-fund="saveExistingFund"
        v-model="currentEditedFund"
      >
      </funds>
      <funds-list v-else-if="!showForm && sourceOfFunds.length !== 0" v-model="sourceOfFunds" @fund-edit="editFund">
      </funds-list>
      <p class="text-muted" v-else>No other source of funds have been added.</p>
    </validation-observer>

    <div class="row pb-2">
      <div class="col-md-12">
        <div class="border-top my-3"></div>
        <h2>EXAMPLES OF DOCUMENTARY EVIDENCE YOU MAY PROVIDE</h2>
        <ul>
          <li>
            <h5>
              In all circumstances. Bank Statements or Payment Books for at least six months showing the entirety of
              your required funds. If more than one account is to be used, please provide copies for each. A P60 or
              three months’ worth of payslips from your employer as a reference to your savings
            </h5>
          </li>
          <li>
            <h5>
              If the funds are in an ISA. Help To Buy ISA Statements from your ISA provider dating back to when this was
              opened. To enable us to drawdown the Government Help To Buy ISA Bonus we will require a closing statement
              from your ISA provider along with a completed First Time Buyer Declaration. Please contact us regarding
              completion timescales before closing your account.
            </h5>
          </li>
          <li>
            <h5>
              If the funds are a Gift/Loan from family/friends. A Passport of driving Licence from the person(s)
              providing the gift along with confirmation and evidence of the source of funds from the person(s) in the
              same manner as requested from yourself. We will provide you with a gifted deposit declaration form to be
              signed by the giftor once you have confirmed that funds are to be gifted.
            </h5>
          </li>
          <li>
            <h5>
              If the funds are from Sale of Investments/Stocks/Shares. Statements for at least six months from your
              investment provider or a statement showing settlement from your investment provider along with a bank
              statement showing the funds being received by you
            </h5>
          </li>
          <li>
            <h5>
              If the funds are from the Sale of Property/Remortgage/Equity Release. A Solicitors’ letter and completion
              statement from the solicitor showing the proceeds being released along with the supporting bank statement
              showing the money being paid in
            </h5>
          </li>
          <li>
            <h5>
              If the Funds are from a Loan from the Bank/Third Party. The Loan agreement or statement and a bank
              statement showing the funds being received by you
            </h5>
          </li>
          <li>
            <h5>
              If the Funds are from Inheritance. A Solicitors’ letter, executors’ letter or Probate Accounts and a bank
              statement showing the funds being received
            </h5>
          </li>
          <li>
            <h5>
              If the funds are from Maturity or Surrender of Life Assurance/Endowment Policy/ Annity. A Closing
              statement from your provider and a bank statement showing the funds being received
            </h5>
          </li>
          <li>
            <h5>
              If the Funds are from an Insurance Claim. A Letter from your insurance provider and a copy of your bank
              statement showing the funds being received
            </h5>
          </li>
          <li>
            <h5>
              If the Funds are from dividends or Profits from Company/Directors Loan. Latest audited company accounts,
              dividend statement/certificate and a bank statement showing the funds being received
            </h5>
          </li>
          <li>
            <h5>
              If the funds are from a Divorce Settlement. Solicitors letter, Court Order or Judicial separation
              agreement and a bank statement showing the funds being received
            </h5>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped></style>
