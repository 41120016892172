<script>
import AddressLookup from '@/components/forms/inputGroups/addressLookup/AddressLookup';
import { mapMutations, mapState } from 'vuex';
import * as purchaseMutations from '@/store/purchase/mutations';
import * as buildingMutations from '@/store/building/mutations';
import patchPurchase from '@/api/purchases/patchPurchase';
import patchBuilding from '@/api/buildings/patchBuilding';
import postPurchase from '@/api/purchases/postPurchase';
import DatePicker from '@/components/DatePicker';
import CurrencyInput from '@/components/forms/inputGroups/CurrencyInput';
import OpenQuestion from '@/components/forms/inputGroups/OpenQuestion';
import SimpleQuestion from '@/components/forms/inputGroups/SimpleQuestion';
import Modal from '@/components/Modal';
import PurchasingInJointNamesInfo from '@/components/messaging/PurchasingInJointNamesInfo';

const propertyTypesValues = [
  { value: null, text: 'Please choose', disabled: true },
  { value: 'maisonette', text: 'Maisonette' },
  { value: 'house', text: 'House' },
  { value: 'flat', text: 'Flat' },
  { value: 'other', text: 'Other' },
];

const surplusPaymentMethods = [
  { value: null, text: 'Please choose', disabled: true },
  { value: 'purchase', text: 'Proceeds for Purchase' },
  { value: 'BACS', text: 'Transferred in three days (BACs)' },
  { value: 'transfer', text: 'Transferred same day (CHAPS) *apply fee' },
];

const purchaseTypeValues = [
  { value: null, text: 'Please choose', disabled: true },
  { value: 'freehold', text: 'Freehold' },
  { value: 'leasehold', text: 'Leasehold' },
  { value: 'leasehold - long lease house', text: 'Leasehold - Long Lease House' },
];

const typeOfPurchaseValues = [
  { value: null, text: 'Please choose', disabled: true },
  { value: 'owner_occupier', text: 'Owner/Occupier' },
  { value: 'second_home', text: 'Second Home' },
  { value: 'dependant_relative', text: 'Dependant Relative' },
  { value: 'buy_to_let', text: 'Buy to Let' },
];

const typeOfTenancyValues = [
  { value: null, text: 'Please choose', disabled: true },
  { value: 'joint_tenancy', text: 'Joint Tenancy' },
  { value: 'tenancy_in_common', text: 'Tenancy In Common' },
  { value: 'not_applicable', text: 'Not Applicable' },
];

const yesOrNo = {
  true: 'Yes',
  false: 'No',
};

const yesOrNoOrUnsure = {
  YNU_YES: 'Yes',
  YNU_NO: 'No',
  YNU_UNSURE: 'Unsure',
};

export default {
  name: 'AboutYourPurchase',
  components: {
    AddressLookup,
    DatePicker,
    OpenQuestion,
    CurrencyInput,
    SimpleQuestion,
    Modal,
    PurchasingInJointNamesInfo,
  },
  data() {
    return {
      propertyTypesValues,
      purchaseTypeValues,
      typeOfPurchaseValues,
      typeOfTenancyValues,
      showTypeOfTenancyInfo: false,
      surplusPaymentMethods,
      yesOrNo,
      yesOrNoOrUnsure,
    };
  },
  computed: {
    ...mapState({
      s_buildingId: (state) => state.buildingId,
      s_sortReferId: (state) => state.sortReferId,
      s_purchaseId: (state) => state.purchase.purchaseId,
      s_propertyType: (state) => state.purchase.propertyType,
      s_propertyTypeOther: (state) => state.purchase.propertyTypeOther,
      s_purchaseType: (state) => state.purchase.purchaseType,
      s_typeOfPurchase: (state) => state.purchase.typeOfPurchase,
      s_buyerName: (state) => state.purchase.buyerName,
      s_purchasePrice: (state) => state.purchase.purchasePrice,
      s_typeOfTenancy: (state) => state.purchase.typeOfTenancy,
      s_typeOfTenancyDetails: (state) => state.purchase.typeOfTenancyDetails,
      s_interimAddress: (state) => state.purchase.interimAddress,
      s_interimPhone: (state) => state.purchase.interimPhone,
      s_fixturePrice: (state) => state.purchase.fixturePrice,
      s_specialPurchase: (state) => state.purchase.specialPurchase,
      s_specialPurchaseDetails: (state) => state.purchase.specialPurchaseDetails,
      s_titleNumbers: (state) => state.purchase.titleNumbers,
      s_desiredCompletionDate: (state) => state.purchase.desiredCompletionDate,
      s_exchangeDeadline: (state) => state.purchase.exchangeDeadline,
      s_completionDeadline: (state) => state.purchase.completionDeadline,
      s_surplusPaymentMethod: (state) => state.purchase.surplusPaymentMethod,
      s_purchaseAddress: (state) => state.building.address,
      s_structuralAlterations: (state) => state.purchase.structuralAlterations,
      s_structuralAlterationsDetails: (state) => state.purchase.structuralAlterationsDetails,
      s_structuralAlterationsWithinTenYr: (state) => state.purchase.structuralAlterationsWithinTenYr,
      s_furtherPointsToCheck: (state) => state.purchase.furtherPointsToCheck,
      s_furtherPointsToCheckDetails: (state) => state.purchase.furtherPointsToCheckDetails,
      s_purchaseAddressUpdated: (state) => state.purchase.purchaseAddressUpdated,
      s_buildingInsurer: (state) => state.purchase.buildingInsurer,
      s_passStatementsToBroker: (state) => state.purchase.passStatementsToBroker,
      s_purchase: (state) => state.purchase,
    }),
    purchaseAddressUpdated: {
      get() {
        return this.s_purchaseAddressUpdated;
      },
      set(value) {
        this[`${purchaseMutations.PURCHASE_PREFIX}${purchaseMutations.PURCHASE_PURCHASE_ADDRESS_UPDATED}`](value);
      },
    },
    propertyType: {
      get() {
        return this.s_propertyType;
      },
      set(value) {
        this[`${purchaseMutations.PURCHASE_PREFIX}${purchaseMutations.PURCHASE_SET_PROPERTY_TYPE}`](value);
      },
    },
    propertyTypeOther: {
      get() {
        return this.s_propertyTypeOther;
      },
      set(value) {
        this[`${purchaseMutations.PURCHASE_PREFIX}${purchaseMutations.PURCHASE_SET_PROPERTY_TYPE_OTHER}`](value);
      },
    },
    purchaseAddress: {
      get() {
        return this.s_purchaseAddress;
      },
      set(value) {
        this[`${buildingMutations.BUILDING_PREFIX}${buildingMutations.BUILDING_SET_ADDRESS}`](value);
      },
    },
    purchaseType: {
      get() {
        return this.s_purchaseType;
      },
      set(value) {
        this[`${purchaseMutations.PURCHASE_PREFIX}${purchaseMutations.PURCHASE_SET_PURCHASE_TYPE}`](value);
      },
    },
    typeOfPurchase: {
      get() {
        return this.s_typeOfPurchase;
      },
      set(value) {
        this[`${purchaseMutations.PURCHASE_PREFIX}${purchaseMutations.PURCHASE_SET_TYPE_OF_PURCHASE}`](value);
      },
    },
    buyerName: {
      get() {
        return this.s_buyerName;
      },
      set(value) {
        this[`${purchaseMutations.PURCHASE_PREFIX}${purchaseMutations.PURCHASE_SET_BUYER_NAME}`](value);
      },
    },
    specialPurchase: {
      get() {
        return {
          answer: this.s_specialPurchase,
          description: this.s_specialPurchaseDetails,
        };
      },
      set(value) {
        this[`${purchaseMutations.PURCHASE_PREFIX}${purchaseMutations.PURCHASE_SET_SPECIAL_PURCHASE}`](value.answer);
        this[`${purchaseMutations.PURCHASE_PREFIX}${purchaseMutations.PURCHASE_SET_SPECIAL_PURCHASE_DETAILS}`](
          value.description,
        );
      },
    },
    purchasePrice: {
      get() {
        return this.s_purchasePrice;
      },
      set(value) {
        this[`${purchaseMutations.PURCHASE_PREFIX}${purchaseMutations.PURCHASE_SET_PRICE}`](Number(value));
      },
    },
    typeOfTenancy: {
      get() {
        return this.s_typeOfTenancy;
      },
      set(value) {
        this[`${purchaseMutations.PURCHASE_PREFIX}${purchaseMutations.PURCHASE_SET_TYPE_OF_TENANCY}`](value);
      },
    },
    typeOfTenancyDetails: {
      get() {
        return this.s_typeOfTenancyDetails;
      },
      set(value) {
        this[`${purchaseMutations.PURCHASE_PREFIX}${purchaseMutations.PURCHASE_SET_TYPE_OF_TENANCY_DETAILS}`](value);
      },
    },
    fixturePrice: {
      get() {
        return this.s_fixturePrice;
      },
      set(value) {
        this[`${purchaseMutations.PURCHASE_PREFIX}${purchaseMutations.PURCHASE_SET_FIXTURE_PRICE}`](Number(value));
      },
    },
    titleNumbers: {
      get() {
        return this.s_titleNumbers;
      },
      set(value) {
        this[`${purchaseMutations.PURCHASE_PREFIX}${purchaseMutations.PURCHASE_SET_TITLE_NUMBERS}`](value);
      },
    },
    desiredCompletionDate: {
      get() {
        return this.s_desiredCompletionDate;
      },
      set(value) {
        this[`${purchaseMutations.PURCHASE_PREFIX}${purchaseMutations.PURCHASE_SET_DESIRED_COMPLETION_DATE}`](value);
      },
    },
    exchangeDeadline: {
      get() {
        return this.s_exchangeDeadline;
      },
      set(value) {
        this[`${purchaseMutations.PURCHASE_PREFIX}${purchaseMutations.PURCHASE_SET_EXCHANGE_DEADLINE}`](value);
      },
    },
    completionDeadline: {
      get() {
        return this.s_completionDeadline;
      },
      set(value) {
        this[`${purchaseMutations.PURCHASE_PREFIX}${purchaseMutations.PURCHASE_SET_COMPLETION_DEADLINE}`](value);
      },
    },
    surplusPaymentMethod: {
      get() {
        return this.s_surplusPaymentMethod;
      },
      set(value) {
        this[`${purchaseMutations.PURCHASE_PREFIX}${purchaseMutations.PURCHASE_SET_SURPLUS_PAYMENT_METHOD}`](value);
      },
    },
    interimPhone: {
      get() {
        return this.s_interimPhone;
      },
      set(value) {
        this[`${purchaseMutations.PURCHASE_PREFIX}${purchaseMutations.PURCHASE_SET_INTERIM_PHONE}`](value);
      },
    },
    interimAddress: {
      get() {
        return this.s_interimAddress;
      },
      set(value) {
        this[`${purchaseMutations.PURCHASE_PREFIX}${purchaseMutations.PURCHASE_SET_INTERIM_ADDRESS}`](value);
      },
    },
    structuralAlterations: {
      get() {
        return {
          answer: this.s_structuralAlterations,
          description: this.s_structuralAlterationsDetails,
        };
      },
      set(value) {
        this[`${purchaseMutations.PURCHASE_PREFIX}${purchaseMutations.PURCHASE_SET_STRUCTURAL_ALTERATIONS}`](
          value.answer,
        );
        this[`${purchaseMutations.PURCHASE_PREFIX}${purchaseMutations.PURCHASE_SET_STRUCTURAL_ALTERATIONS_DETAILS}`](
          value.description,
        );
      },
    },
    structuralAlterationsWithinTenYr: {
      get() {
        return {
          answer: this.s_structuralAlterationsWithinTenYr,
        };
      },
      set(value) {
        this[
          `${purchaseMutations.PURCHASE_PREFIX}${purchaseMutations.PURCHASE_SET_STRUCTURAL_ALTERATIONS_WITHIN_TEN_YR}`
        ](value.answer);
      },
    },
    furtherPointsToCheck: {
      get() {
        return {
          answer: this.s_furtherPointsToCheck,
          description: this.s_furtherPointsToCheckDetails,
        };
      },
      set(value) {
        this[`${purchaseMutations.PURCHASE_PREFIX}${purchaseMutations.PURCHASE_SET_FURTHER_POINTS_TO_CHECK}`](
          value.answer,
        );
        this[`${purchaseMutations.PURCHASE_PREFIX}${purchaseMutations.PURCHASE_SET_FURTHER_POINTS_TO_CHECK_DETAILS}`](
          value.description,
        );
      },
    },
    buildingInsurer: {
      get() {
        return this.s_buildingInsurer;
      },
      set(value) {
        this[`${purchaseMutations.PURCHASE_PREFIX}${purchaseMutations.PURCHASE_SET_BUILDING_INSURER}`](value);
      },
    },
    passStatementsToBroker: {
      get() {
        return {
          answer: this.s_passStatementsToBroker,
        };
      },
      set(value) {
        this[`${purchaseMutations.PURCHASE_PREFIX}${purchaseMutations.PURCHASE_SET_PASS_STATEMENTS_TO_BROKER}`](
          value.answer,
        );
      },
    },
  },
  methods: {
    ...mapMutations([
      `${purchaseMutations.PURCHASE_PREFIX}${purchaseMutations.PURCHASE_SET_PROPERTY_TYPE}`,
      `${purchaseMutations.PURCHASE_PREFIX}${purchaseMutations.PURCHASE_SET_PROPERTY_TYPE_OTHER}`,
      `${purchaseMutations.PURCHASE_PREFIX}${purchaseMutations.PURCHASE_SET_PURCHASE_TYPE}`,
      `${purchaseMutations.PURCHASE_PREFIX}${purchaseMutations.PURCHASE_SET_TYPE_OF_PURCHASE}`,
      `${purchaseMutations.PURCHASE_PREFIX}${purchaseMutations.PURCHASE_SET_BUYER_NAME}`,
      `${purchaseMutations.PURCHASE_PREFIX}${purchaseMutations.PURCHASE_SET_PRICE}`,
      `${purchaseMutations.PURCHASE_PREFIX}${purchaseMutations.PURCHASE_SET_TYPE_OF_TENANCY}`,
      `${purchaseMutations.PURCHASE_PREFIX}${purchaseMutations.PURCHASE_SET_TYPE_OF_TENANCY_DETAILS}`,
      `${purchaseMutations.PURCHASE_PREFIX}${purchaseMutations.PURCHASE_SET_FIXTURE_PRICE}`,
      `${purchaseMutations.PURCHASE_PREFIX}${purchaseMutations.PURCHASE_SET_TITLE_NUMBERS}`,
      `${purchaseMutations.PURCHASE_PREFIX}${purchaseMutations.PURCHASE_SET_DESIRED_COMPLETION_DATE}`,
      `${purchaseMutations.PURCHASE_PREFIX}${purchaseMutations.PURCHASE_SET_EXCHANGE_DEADLINE}`,
      `${purchaseMutations.PURCHASE_PREFIX}${purchaseMutations.PURCHASE_SET_COMPLETION_DEADLINE}`,
      `${purchaseMutations.PURCHASE_PREFIX}${purchaseMutations.PURCHASE_SET_SURPLUS_PAYMENT_METHOD}`,
      `${purchaseMutations.PURCHASE_PREFIX}${purchaseMutations.PURCHASE_SET_INTERIM_ADDRESS}`,
      `${purchaseMutations.PURCHASE_PREFIX}${purchaseMutations.PURCHASE_SET_INTERIM_PHONE}`,
      `${purchaseMutations.PURCHASE_PREFIX}${purchaseMutations.PURCHASE_SET_RELATED_CASES}`,
      `${purchaseMutations.PURCHASE_PREFIX}${purchaseMutations.PURCHASE_UPDATED_RELATED_CASES_ITEM}`,
      `${purchaseMutations.PURCHASE_PREFIX}${purchaseMutations.PURCHASE_ADD_NEW_RELATED_CASE}`,
      `${purchaseMutations.PURCHASE_PREFIX}${purchaseMutations.PURCHASE_SET_SPECIAL_PURCHASE}`,
      `${purchaseMutations.PURCHASE_PREFIX}${purchaseMutations.PURCHASE_SET_SPECIAL_PURCHASE_DETAILS}`,
      `${purchaseMutations.PURCHASE_PREFIX}${purchaseMutations.PURCHASE_SET_STRUCTURAL_ALTERATIONS}`,
      `${purchaseMutations.PURCHASE_PREFIX}${purchaseMutations.PURCHASE_SET_STRUCTURAL_ALTERATIONS_DETAILS}`,
      `${purchaseMutations.PURCHASE_PREFIX}${purchaseMutations.PURCHASE_SET_STRUCTURAL_ALTERATIONS_WITHIN_TEN_YR}`,
      `${purchaseMutations.PURCHASE_PREFIX}${purchaseMutations.PURCHASE_SET_FURTHER_POINTS_TO_CHECK}`,
      `${purchaseMutations.PURCHASE_PREFIX}${purchaseMutations.PURCHASE_SET_FURTHER_POINTS_TO_CHECK_DETAILS}`,
      `${purchaseMutations.PURCHASE_PREFIX}${purchaseMutations.PURCHASE_SET_BUILDING_INSURER}`,
      `${purchaseMutations.PURCHASE_PREFIX}${purchaseMutations.PURCHASE_PURCHASE_ADDRESS_UPDATED}`,
      `${purchaseMutations.PURCHASE_PREFIX}${purchaseMutations.PURCHASE_SET_PASS_STATEMENTS_TO_BROKER}`,
      `${buildingMutations.BUILDING_PREFIX}${buildingMutations.BUILDING_SET_ADDRESS}`,
    ]),
    async saveToApi() {
      const isValid = await this.$refs.observer.validate();
      if (!isValid) {
        this.$el.querySelector('[aria-invalid=true]').focus();
        return false; // If the return result is false, tab switch is restricted
      }
      this.saving = true;
      const preparedData = {
        buildingId: this.s_buildingId,
        sortReferId: this.s_sortReferId,
        propertyType: this.s_propertyType,
        propertyTypeOther: this.s_propertyTypeOther,
        typeOfPurchase: this.s_typeOfPurchase,
        purchasePrice: this.s_purchasePrice,
        typeOfTenancy: this.s_typeOfTenancy,
        typeOfTenancyDetails: this.s_typeOfTenancyDetails,
        purchaseType: this.s_purchaseType,
        buyerName: this.s_buyerName,
        fixturePrice: this.s_fixturePrice,
        purchaseAddress: this.s_purchaseAddress,
        titleNumbers: this.s_titleNumbers,
        desiredCompletionDate: this.s_desiredCompletionDate,
        exchangeDeadline: this.s_exchangeDeadline,
        completionDeadline: this.s_completionDeadline,
        surplusPaymentMethod: this.s_surplusPaymentMethod,
        interimAddress: this.s_interimAddress,
        interimPhone: this.s_interimPhone,
        specialPurchase: this.s_specialPurchase,
        specialPurchaseDetails: this.s_specialPurchaseDetails,
        structuralAlterations: this.s_structuralAlterations,
        structuralAlterationsDetails: this.s_structuralAlterationsDetails,
        structuralAlterationsWithinTenYr: this.s_structuralAlterationsWithinTenYr,
        furtherPointsToCheck: this.s_furtherPointsToCheck,
        furtherPointsToCheckDetails: this.s_furtherPointsToCheckDetails,
        passStatementsToBroker: this.s_passStatementsToBroker,
        buildingInsurer: this.s_buildingInsurer,
      };

      if (this.s_purchaseId) {
        await patchPurchase({ ...preparedData, ...{ id: this.s_purchaseId } });
      } else {
        await postPurchase(preparedData);
      }

      await patchBuilding({
        id: this.s_buildingId,
        address: this.s_purchaseAddress,
      });

      // force rerender review page
      this.purchaseAddressUpdated = true;

      this.saving = false;
      return true;
    },
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },
    toggleTypeOfTenancyInfo(value) {
      this.showTypeOfTenancyInfo = value;
    },
  },
};
</script>

<template>
  <validation-observer ref="observer">
    <div>
      <h2>About Your Purchase</h2>

      <div class="row pb-2">
        <div class="col-md-3">
          <h5>
            <label class="mandatory"> Property Type: </label>
          </h5>
        </div>
        <div class="col-md-9">
          <ValidationProvider name="property type" rules="required" v-slot="validationContext">
            <b-form-select
              v-model="propertyType"
              :options="propertyTypesValues"
              id="property-type"
              class="form-control bg-white"
              :state="getValidationState(validationContext)"
            ></b-form-select>
            <b-form-invalid-feedback data-error-name="property-type-error"
              >{{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </ValidationProvider>
        </div>
      </div>

      <div class="row pb-2" v-if="propertyType === 'other'">
        <div class="col-md-3">
          <h5>
            <label class="mandatory"> Other Property Type: </label>
          </h5>
        </div>

        <div class="col-md-9">
          <ValidationProvider name="other property type" rules="required|min:3" v-slot="validationContext">
            <b-form-input
              :state="getValidationState(validationContext)"
              class="form-control"
              id="property-type-other"
              v-model="propertyTypeOther"
            ></b-form-input>
            <b-form-invalid-feedback data-error-name="property-type-other-error"
              >{{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </ValidationProvider>
        </div>
      </div>

      <div class="row pb-2">
        <div class="col-md-3">
          <h5>
            <label class="mandatory"> Property Tenure: </label>
          </h5>
        </div>

        <div class="col-md-9">
          <ValidationProvider name="property tenure" rules="required" v-slot="validationContext">
            <b-form-select
              v-model="purchaseType"
              :options="purchaseTypeValues"
              id="purchase-type"
              class="form-control bg-white"
              :state="getValidationState(validationContext)"
            ></b-form-select>
            <b-form-invalid-feedback data-error-name="purchase-type-error"
              >{{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </ValidationProvider>
        </div>
      </div>

      <div class="row pb-2">
        <div class="col-md-3">
          <h5>
            <label class="mandatory" for="type-of-purchase">Type of Purchase:</label>
          </h5>
        </div>

        <div class="col-md-9">
          <ValidationProvider name="type of purchase" rules="required" v-slot="validationContext">
            <b-form-select
              v-model="typeOfPurchase"
              :options="typeOfPurchaseValues"
              id="type-of-purchase"
              class="form-control bg-white"
              :state="getValidationState(validationContext)"
            ></b-form-select>
            <b-form-invalid-feedback data-error-name="type-of-purchase-error"
              >{{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </ValidationProvider>
        </div>
      </div>

      <div class="row pb-2">
        <div class="col-md-3">
          <h5>
            <label> Purchase Address: </label>
          </h5>
        </div>
      </div>

      <address-lookup :key="purchaseAddressUpdated" :addResidency="false" v-model="purchaseAddress"></address-lookup>

      <div class="row pb-2">
        <div class="col-md-3">
          <h5>
            <label class="mandatory"> Purchase Price: </label>
          </h5>
        </div>

        <div class="col-md-9">
          <currency-input
            v-model="purchasePrice"
            validationFieldName="sale price"
            id="sale-price"
            :required="true"
          ></currency-input>
        </div>
      </div>

      <div class="row pb-2">
        <div class="col-md-3">
          <h5>
            <label> If you are Purchasing in Joint Names: </label>
          </h5>
        </div>

        <div class="col-md-9">
          <div class="d-flex align-items-center justify-content-md-start pt-1 pb-1">
            <span class="text-info" @click="toggleTypeOfTenancyInfo(true)">
              <i class="icon-info22 mr-2 icon"></i>
              <span class="text-nowrap">More Info</span>
            </span>
          </div>
        </div>
      </div>

      <div class="row pb-2">
        <div class="col-md-3">
          <h5>
            <label class="mandatory"> Proposed Ownership? </label>
          </h5>
        </div>
        <div class="col-md-9">
          <ValidationProvider name="type of tenancy" rules="required" v-slot="validationContext">
            <b-form-select
              v-model="typeOfTenancy"
              :options="typeOfTenancyValues"
              id="type-of-tenancy"
              class="form-control bg-white"
              :state="getValidationState(validationContext)"
            ></b-form-select>
            <b-form-invalid-feedback data-error-name="type-of-tenancy-error"
              >{{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </ValidationProvider>
        </div>
      </div>

      <div class="row pb-2" v-if="typeOfTenancy === 'tenancy_in_common'">
        <div class="col-md-12">
          <h5>
            <label for="type-of-tenancy-details" class="mandatory">
              Please provide details of the Tenants in Common in shares in accordance with a Declaration of Trust:
            </label>
          </h5>

          <validation-provider name="type of tenancy details" rules="required" v-slot="validationContext">
            <b-form-textarea
              v-model="typeOfTenancyDetails"
              id="type-of-tenancy-details"
              rows="4"
              :state="getValidationState(validationContext)"
            ></b-form-textarea>
            <b-form-invalid-feedback v-if="validationContext.errors[0]" data-error-name="textarea-error"
              >{{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </validation-provider>
        </div>
      </div>

      <div class="row pb-2">
        <div class="col-md-3">
          <h5>
            <label> Price for Fixtures & Fittings (if any): </label>
          </h5>
        </div>

        <div class="col-md-9">
          <currency-input
            v-model="fixturePrice"
            validationFieldName="fixture-price"
            id="fixture-price"
          ></currency-input>
        </div>
      </div>

      <div class="row pb-2">
        <div class="col-md-3">
          <h5>
            <label> Desired Completion Date: </label>
          </h5>
        </div>

        <div class="col-md-9">
          <date-picker v-model="desiredCompletionDate" id="desired-completion-date" minimumView="day" />
        </div>
      </div>

      <div class="row pb-2">
        <div class="col-md-3">
          <h5 class="line-height-base">
            <label>
              Who is arranging the Buildings insurance? Please note that this will need to be in place for exchange of
              contracts
            </label>
          </h5>
        </div>

        <div class="col-md-9">
          <validation-provider name="building insurer" :rules="{ required: false }" v-slot="validationContext" slim>
            <b-form-group label-size="lg" label-for="lender">
              <b-form-input
                v-model="buildingInsurer"
                id="buildingInsurer"
                :state="getValidationState(validationContext)"
              ></b-form-input>
              <b-form-invalid-feedback data-error-name="building-insurer-error"
                >{{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </div>
      </div>

      <div class="row pb-2">
        <div class="col-md-12">
          <h5>
            <label>
              If not intending to occupy the property being purchased, please provide a correspondence address to use
              after completion of your purchase:
            </label>
          </h5>
        </div>
      </div>

      <address-lookup :addResidency="false" :requiresValidation="false" v-model="interimAddress"></address-lookup>

      <div class="row pb-2">
        <div class="col-md-12">
          <h2>Alterations</h2>
        </div>
      </div>

      <div class="row pb-2">
        <div class="col-md-12">
          <open-question
            v-model="structuralAlterations"
            labelName="structuralAlterationsLabelName"
            title="Are you aware of any structural alterations or extensions that have been made to the property you are buying?"
            descriptionTitle="If Yes, please give details:"
            validationFieldName="structural alterations"
            dropdownName="awareOfAlterationsDropdownName"
            textAreaName="awareOfAlterationsTextAreaName"
          ></open-question>
        </div>
      </div>

      <div class="row pb-2" v-if="structuralAlterations.answer">
        <div class="col-md-12">
          <simple-question
            :dropdownOptions="yesOrNoOrUnsure"
            labelName="structuralAlterationsWithinTenYrLabelName"
            title="Does it look as though these alterations have been carried out in the past ten years?"
            v-model="structuralAlterationsWithinTenYr"
            validationFieldName="structural alterations within ten years"
          />
        </div>
      </div>

      <div class="row pb-2">
        <div class="col-md-12">
          <open-question
            v-model="furtherPointsToCheck"
            labelName="alterationChecksLabelName"
            title="Are there any further points you would like us to check in respect of the property you intend to buy e.g boundaries, development of nearby land, repairs to be considered?"
            descriptionTitle="If so, please provide details:"
            validationFieldName="alteration checks"
            dropdownName="alterationChecksDropdownName"
            textAreaName="alterationChecksTextAreaName"
          ></open-question>
        </div>
      </div>

      <div class="row pb-2">
        <div class="col-md-12">
          <div class="section-title">
            <h3>Permission to discuss your matter</h3>
          </div>
        </div>
      </div>

      <div class="row pb-2">
        <div class="col-md-12">
          <simple-question
            :dropdownOptions="yesOrNo"
            labelName="passStatementsToBrokerLabelName"
            title="Are you happy for us to share your matter details and documents with your broker/estate agent (if requested by
            them) during the course of your matter?"
            v-model="passStatementsToBroker"
            validationFieldName="pass statements to broker"
          />
        </div>
      </div>

      <modal
        v-if="showTypeOfTenancyInfo"
        :show-buttons="true"
        closeButtonText="Close"
        :fullModalWidth="false"
        @proceed="toggleTypeOfTenancyInfo"
      >
        <purchasing-in-joint-names-info />
      </modal>
    </div>
  </validation-observer>
</template>

<style lang="scss" scoped></style>
