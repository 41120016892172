import axios from 'axios';

export default ({
  clientIds,
  authorityPurchase,
  authorityPurchaseDetails,
  propertyType,
  purchaseType,
  typeOfPurchase,
  buyerName,
  purchasePrice,
  typeOfTenancy,
  typeOfTenancyDetails,
  fixturePrice,
  agentFee,
  agentFeeType,
  agentFeePaymentPermission,
  relatedCase,
  relatedCases,
  specialPurchase,
  specialPurchaseDetails,
  occupierIds,
  mortgagesOrCharges,
  leaseholdToFreehold,
  titleNumbers,
  desiredCompletionDate,
  exchangeDeadline,
  completionDeadline,
  surplusPaymentMethod,
  interimAddress,
  interimPhone,
  sagepayTransactionId,
  structuralAlterations,
  structuralAlterationsDetails,
  structuralAlterationsWithinTenYr,
  furtherPointsToCheck,
  furtherPointsToCheckDetails,
  brokerOrIfa,
  brokerOrIfaDetails,
  sourceOfFunds,
  buildingInsurer,
  soldByEstateAgent,
  solicitorContactDetails,
  buildingId,
}) =>
  axios.post(`${process.env.VUE_APP_API_ENDPOINT}/purchases`, {
    client_ids: clientIds,
    authority_purchase: authorityPurchase,
    authority_purchase_details: authorityPurchaseDetails,
    property_type: propertyType,
    purchase_type: purchaseType,
    type_of_purchase: typeOfPurchase,
    buyer_name: buyerName,
    purchase_price: purchasePrice,
    type_of_tenancy: typeOfTenancy,
    type_of_tenancy_details: typeOfTenancyDetails,
    fixture_price: fixturePrice,
    agent_fee: agentFee,
    agent_fee_type: agentFeeType,
    agent_fee_payment_permission: agentFeePaymentPermission,
    related_case: relatedCase,
    related_cases: relatedCases,
    special_purchase: specialPurchase,
    special_purchase_details: specialPurchaseDetails,
    occupier_ids: occupierIds,
    mortgages_or_charges: mortgagesOrCharges,
    leasehold_to_freehold: leaseholdToFreehold,
    title_numbers: titleNumbers,
    desired_completion_date: desiredCompletionDate,
    exchange_deadline: exchangeDeadline,
    completion_deadline: completionDeadline,
    surplus_payment_method: surplusPaymentMethod,
    interim_address: interimAddress,
    interim_phone: interimPhone,
    sagepay_transaction_id: sagepayTransactionId,
    structural_alterations: structuralAlterations,
    structural_alterations_details: structuralAlterationsDetails,
    structural_alterations_within_ten_yr: structuralAlterationsWithinTenYr,
    further_points_to_check: furtherPointsToCheck,
    further_points_to_check_details: furtherPointsToCheckDetails,
    broker_or_ifa: brokerOrIfa,
    broker_or_ifa_details: brokerOrIfaDetails,
    source_of_funds: sourceOfFunds,
    building_insurer: buildingInsurer,
    sold_by_estate_agent: soldByEstateAgent,
    solicitor_contact_details: solicitorContactDetails,
    building_id: buildingId,
  });
