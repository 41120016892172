import axios from 'axios';

export default ({
  id,
  clientIds,
  authorityPurchase,
  authorityPurchaseDetails,
  propertyType,
  propertyTypeOther,
  purchaseType,
  typeOfPurchase,
  buyerName,
  purchasePrice,
  typeOfTenancy,
  typeOfTenancyDetails,
  fixturePrice,
  agentFee,
  agentFeeType,
  agentFeePaymentPermission,
  relatedCase,
  relatedCases,
  specialPurchase,
  specialPurchaseDetails,
  occupierIds,
  mortgages,
  leaseholdToFreehold,
  titleNumbers,
  desiredCompletionDate,
  exchangeDeadline,
  completionDeadline,
  surplusPaymentMethod,
  interimAddress,
  interimPhone,
  sagepayTransactionId,
  structuralAlterations,
  structuralAlterationsDetails,
  structuralAlterationsWithinTenYr,
  furtherPointsToCheck,
  furtherPointsToCheckDetails,
  brokerOrIfa,
  brokerOrIfaDetails,
  sourceOfFunds,
  buildingInsurer,
  soldByEstateAgent,
  solicitorContactDetails,
  estateAgentId,
  passStatementsToBroker,
  bankName,
  bankAccountName,
  bankAccountNumber,
  bankBacsOrChaps,
  bankSortCode,
  additionalInfo,
  buildingId,
}) => {
  const preparedData = {
    client_ids: clientIds,
    authority_purchase: authorityPurchase,
    authority_purchase_details: authorityPurchaseDetails,
    property_type: propertyType,
    property_type_other: propertyTypeOther,
    purchase_type: purchaseType,
    type_of_purchase: typeOfPurchase,
    buyer_name: buyerName,
    purchase_price: purchasePrice,
    type_of_tenancy: typeOfTenancy,
    type_of_tenancy_details: typeOfTenancyDetails,
    fixture_price: fixturePrice,
    agent_fee: agentFee,
    agent_fee_type: agentFeeType,
    agent_fee_payment_permission: agentFeePaymentPermission,
    related_case: relatedCase,
    related_cases: relatedCases,
    special_purchase: specialPurchase,
    special_purchase_details: specialPurchaseDetails,
    occupier_ids: occupierIds,
    mortgages: mortgages,
    leasehold_to_freehold: leaseholdToFreehold,
    title_numbers: titleNumbers,
    desired_completion_date: desiredCompletionDate,
    exchange_deadline: exchangeDeadline,
    completion_deadline: completionDeadline,
    surplus_payment_method: surplusPaymentMethod,
    interim_address: interimAddress,
    interim_phone: interimPhone,
    sagepay_transaction_id: sagepayTransactionId,
    structural_alterations: structuralAlterations,
    structural_alterations_details: structuralAlterationsDetails,
    structural_alterations_within_ten_yr: structuralAlterationsWithinTenYr,
    further_points_to_check: furtherPointsToCheck,
    further_points_to_check_details: furtherPointsToCheckDetails,
    broker_or_ifa: brokerOrIfa,
    broker_or_ifa_details: brokerOrIfaDetails,
    source_of_funds: sourceOfFunds,
    building_insurer: buildingInsurer,
    sold_by_estate_agent: soldByEstateAgent,
    solicitor_contact_details: solicitorContactDetails,
    estate_agent_id: estateAgentId,
    pass_statements_to_broker: passStatementsToBroker,
    additional_info: additionalInfo,
    building_id: buildingId,
  };

  // Overwrite values for not required fields by passing empty string
  if (typeof structuralAlterations !== 'undefined' && structuralAlterations !== true) {
    preparedData.structural_alterations_details = '';
    preparedData.structural_alterations_within_ten_yr = '';
  }

  if (typeof furtherPointsToCheck !== 'undefined' && furtherPointsToCheck !== true) {
    preparedData.further_points_to_check_details = '';
  }

  if (typeof soldByEstateAgent !== 'undefined' && soldByEstateAgent !== false) {
    preparedData.solicitor_contact_details = '';
  }

  if (typeof brokerOrIfa !== 'undefined' && brokerOrIfa !== true) {
    preparedData.broker_or_ifa_details = { ...{} };
  }

  if (typeof typeOfTenancy !== 'undefined' && typeOfTenancy !== 'tenancy_in_common') {
    preparedData.type_of_tenancy_details = '';
  }

  if (typeof propertyType !== 'undefined' && propertyType !== 'other') {
    preparedData.property_type_other = '';
  }

  if (bankName || bankAccountName || bankAccountNumber || bankSortCode || bankBacsOrChaps) {
    if (bankSortCode) {
      bankSortCode = String(bankSortCode).replace(/-/g, '');
    }

    preparedData.bank_details = {
      bank_name: bankName,
      account_name: bankAccountName,
      account_number: bankAccountNumber,
      sort_code: bankSortCode,
      bacs_or_chaps: bankBacsOrChaps,
    };
  }

  // Remove all undefined and null from payload
  const purchaseData = Object.entries(preparedData).reduce(
    (a, [k, v]) => (v === undefined || v === null ? a : ((a[k] = v), a)),
    {},
  );

  return axios.patch(`${process.env.VUE_APP_API_ENDPOINT}/purchases/${id}`, purchaseData);
};
