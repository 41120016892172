<script>
import AddressLookup from '@/components/forms/inputGroups/addressLookup/AddressLookup';

const rawBrokerOrIfaDetails = {
  allow_contact: null,
  broker_ifa_name: '',
  broker_ifa_email: '',
  broker_ifa_phone: '',
  address: null,
};

const yesOrNo = [
  { text: 'Yes', value: true },
  { text: 'No', value: false },
];

export default {
  components: { AddressLookup },
  name: 'BrokerOrIfaDetails',
  props: {
    value: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      saving: false,
      yesOrNo,
      brokerOrIfaDetails: {
        ...rawBrokerOrIfaDetails,
        ...this.value,
      },
    };
  },
  methods: {
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },
    triggerUpdate() {
      this.$emit('input', this.brokerOrIfaDetails);
    },
  },
};
</script>

<template>
  <div>
    <div class="row pb-2">
      <div class="col-md-3">
        <h5>
          <label class="mandatory"> Broker / IFA Name: </label>
        </h5>
      </div>

      <div class="col-md-9">
        <ValidationProvider name="buyer name" rules="required" v-slot="validationContext">
          <b-form-input
            v-model="brokerOrIfaDetails.broker_ifa_name"
            id="buyer-name"
            class="form-control"
            :state="getValidationState(validationContext)"
          ></b-form-input>
          <b-form-invalid-feedback data-error-name="buyer-name-error"
            >{{ validationContext.errors[0] }}
          </b-form-invalid-feedback>
        </ValidationProvider>
      </div>
    </div>

    <div class="row pb-2">
      <div class="col-md-3">
        <h5>
          <label class="mandatory"> Broker / IFA Email: </label>
        </h5>
      </div>

      <div class="col-md-9">
        <ValidationProvider name="buyer email" rules="email|required" v-slot="validationContext">
          <b-form-input
            v-model="brokerOrIfaDetails.broker_ifa_email"
            id="buyer-email"
            class="form-control"
            :state="getValidationState(validationContext)"
          ></b-form-input>
          <b-form-invalid-feedback data-error-name="buyer-email-error"
            >{{ validationContext.errors[0] }}
          </b-form-invalid-feedback>
        </ValidationProvider>
      </div>
    </div>

    <div class="row pb-2">
      <div class="col-md-3">
        <h5>
          <label class="mandatory"> Broker / IFA Telephone Number: </label>
        </h5>
      </div>

      <div class="col-md-9">
        <ValidationProvider name="broker/IFA number" rules="phone_number|required" v-slot="validationContext">
          <b-form-input
            v-model="brokerOrIfaDetails.broker_ifa_phone"
            id="buyer-phone"
            class="form-control"
            :state="getValidationState(validationContext)"
          ></b-form-input>
          <b-form-invalid-feedback data-error-name="buyer-phone-error"
            >{{ validationContext.errors[0] }}
          </b-form-invalid-feedback>
        </ValidationProvider>
      </div>
    </div>

    <div class="row pb-2">
      <div class="col-md-12">
        <h5>
          <label> Broker / IFA Address: </label>
        </h5>
      </div>
    </div>

    <div class="row pb-2">
      <div class="col-md-12">
        <address-lookup
          :addResidency="false"
          :requiresValidation="false"
          v-model="brokerOrIfaDetails.address"
        ></address-lookup>
      </div>
    </div>

    <div class="row pb-2">
      <div class="col-md-3">
        <h5>
          <label for="allow-contact" class="line-height-base mandatory">
            Please confirm we may contact and discuss the mortgage arrangements with your IFA / Broker direct if we
            believe it is appropriate?
          </label>
        </h5>
      </div>

      <div class="col-md-9">
        <ValidationProvider name="allow contact" rules="required" v-slot="validationContext">
          <b-form-select
            v-model="brokerOrIfaDetails.allow_contact"
            :options="yesOrNo"
            id="allow-contact"
            class="form-control bg-white"
            :state="getValidationState(validationContext)"
          ></b-form-select>
          <b-form-invalid-feedback data-error-name="allow-contact-error"
            >{{ validationContext.errors[0] }}
          </b-form-invalid-feedback>
        </ValidationProvider>
      </div>
    </div>
  </div>
</template>
