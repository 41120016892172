<script>
import { mapMutations, mapState } from 'vuex';
import * as mutations from '@/store/purchase/mutations';
import Debt from '@/components/forms/purchaseClientQuestionnaire/mortgages/Debt';
import DebtsList from '@/components/forms/purchaseClientQuestionnaire/mortgages/DebtList';
import BrokerOrIfaDetails from '@/components/forms/purchaseClientQuestionnaire/mortgages/BrokerOrIfaDetails';
import patchPurchase from '@/api/purchases/patchPurchase';

const yesOrNo = [
  { text: 'Yes', value: true },
  { text: 'No', value: false },
];

export default {
  name: 'Mortgages',
  components: { DebtsList, Debt, BrokerOrIfaDetails },
  computed: {
    ...mapState({
      s_purchase: (state) => state.purchase,
    }),
    mortgages: {
      get() {
        return this.s_purchase.mortgages;
      },
      set(value) {
        this[`${mutations.PURCHASE_PREFIX}${mutations.PURCHASE_SET_MORTGAGES}`](value);
      },
    },
    brokerOrIfa: {
      get() {
        return this.s_purchase.brokerOrIfa;
      },
      set(value) {
        this[`${mutations.PURCHASE_PREFIX}${mutations.PURCHASE_SET_BROKER_OR_IFA}`](value);
      },
    },
    brokerOrIfaDetails: {
      get() {
        return this.s_purchase.brokerOrIfaDetails;
      },
      set(value) {
        this[`${mutations.PURCHASE_PREFIX}${mutations.PURCHASE_SET_BROKER_OR_IFA_DETAILS}`](value);
      },
    },
  },
  data() {
    return {
      showForm: false,
      currentEditedDebt: null,
      yesOrNo,
    };
  },
  methods: {
    ...mapMutations([
      `${mutations.PURCHASE_PREFIX}${mutations.PURCHASE_SET_MORTGAGES}`,
      `${mutations.PURCHASE_PREFIX}${mutations.PURCHASE_ADD_MORTGAGES}`,
      `${mutations.PURCHASE_PREFIX}${mutations.PURCHASE_SET_BROKER_OR_IFA}`,
      `${mutations.PURCHASE_PREFIX}${mutations.PURCHASE_SET_BROKER_OR_IFA_DETAILS}`,
    ]),
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },
    showDebtForm() {
      this.showForm = true;
      this.currentEditedDebt = null;
    },
    showList() {
      this.showForm = false;
    },
    saveNewDebt(event) {
      this[`${mutations.PURCHASE_PREFIX}${mutations.PURCHASE_ADD_MORTGAGES}`](event);
      this.showList();
    },
    editDebt(debtIndexToBeEdited) {
      this.currentEditedDebt = this.mortgages[debtIndexToBeEdited];

      this.showForm = true;
    },
    saveExistingDebt(debt) {
      const indexForDebtToBeUpdated = this.mortgages.indexOf(this.currentEditedDebt);

      this.mortgages[indexForDebtToBeUpdated] = debt;
      this.currentEditedDebt = debt;

      this.showList();
    },
    async saveToApi() {
      const isValid = await this.$refs.observer.validate();
      if (!isValid || this.showForm) {
        this.$el.querySelector('[aria-invalid=true]').focus();
        return false; // If the return result is false, tab switch is restricted
      }
      this.saving = true;

      if (this.brokerOrIfa) {
        await this.$refs.brokerOrIfaDetails.triggerUpdate();
      }

      await patchPurchase({
        id: this.s_purchase.purchaseId,
        mortgages: this.s_purchase.mortgages,
        brokerOrIfa: this.s_purchase.brokerOrIfa,
        brokerOrIfaDetails: this.s_purchase.brokerOrIfaDetails,
      });
      this.saving = false;

      return true;
    },
  },
};
</script>

<template>
  <div>
    <h2>Mortgages</h2>
    <div class="row pb-1">
      <div class="col-md-9">
        <h5>
          Are you Purchasing the property with a mortgage? If yes, please click the add button to provide more
          information:
        </h5>
      </div>

      <div class="col-md-3 d-flex justify-content-end">
        <button class="btn btn-add" @click="showDebtForm" v-if="!showForm"></button>
      </div>
    </div>

    <div class="border-top my-3"></div>
    <ValidationObserver tag="div" ref="observer">
      <debt
        v-if="showForm"
        @cancel="showList()"
        @save-new-debt="saveNewDebt"
        @save-existing-debt="saveExistingDebt"
        v-model="currentEditedDebt"
      >
      </debt>
      <debts-list v-else-if="!showForm && mortgages.length !== 0" v-model="mortgages" @debt-edit="editDebt">
      </debts-list>
      <p class="text-muted" v-else>No mortgages have been added.</p>

      <div class="border-top my-3"></div>

      <!-- Mortgage Broker or IFA -->

      <div class="row pb-2">
        <div class="col-md-3">
          <h5>
            <label class="broker-or-ifa mandatory"> Are you using a Mortgage Broker or IFA? </label>
          </h5>
        </div>

        <div class="col-md-9">
          <ValidationProvider name="broker or ifa" rules="required" v-slot="validationContext">
            <b-form-select
              v-model="brokerOrIfa"
              :options="yesOrNo"
              id="broker-or-ifa"
              class="form-control bg-white"
              :state="getValidationState(validationContext)"
            ></b-form-select>
            <b-form-invalid-feedback data-error-name="broker-or-ifa-error"
              >{{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </ValidationProvider>
        </div>
      </div>

      <!-- Show only when Mortgage broker or IFA has been used -->
      <broker-or-ifa-details v-if="brokerOrIfa === true" v-model="brokerOrIfaDetails" ref="brokerOrIfaDetails">
      </broker-or-ifa-details>
      <!-- Mortgage broker or IFA has been used -->
    </ValidationObserver>
  </div>
</template>
