<script>
import AddressLookup from '@/components/forms/inputGroups/addressLookup/AddressLookup';
import { mapMutations, mapState } from 'vuex';
import * as firmMutations from '@/store/firm/mutations';
import * as purchaseMutations from '@/store/purchase/mutations';
import SimpleQuestion from '@/components/forms/inputGroups/SimpleQuestion';
import patchPurchase from '@/api/purchases/patchPurchase';
import patchFirm from '@/api/firms/patchFirm';
import postFirm from '@/api/firms/postFirm';

const yesOrNo = {
  true: 'Yes',
  false: 'No',
};

export default {
  name: 'SellerEstateAgent',
  components: {
    AddressLookup,
    SimpleQuestion,
  },
  computed: {
    ...mapState({
      s_estateAgentId: (state) => state.purchase.estateAgentId,
      s_purchaseId: (state) => state.purchase.purchaseId,
      s_soldByEstateAgent: (state) => state.purchase.soldByEstateAgent,
      s_solicitorContactDetails: (state) => state.purchase.solicitorContactDetails,
      s_name: (state) => state.firm.name,
      s_phone: (state) => state.firm.phone,
      s_email: (state) => state.firm.email,
      s_address: (state) => state.firm.address,
    }),
    name: {
      get() {
        return this.s_name;
      },
      set(value) {
        this[`${firmMutations.FIRM_PREFIX}${firmMutations.FIRM_SET_NAME}`](value);
      },
    },
    phone: {
      get() {
        return this.s_phone;
      },
      set(value) {
        this[`${firmMutations.FIRM_PREFIX}${firmMutations.FIRM_SET_PHONE}`](value);
      },
    },
    email: {
      get() {
        return this.s_email;
      },
      set(value) {
        this[`${firmMutations.FIRM_PREFIX}${firmMutations.FIRM_SET_EMAIL}`](value);
      },
    },
    address: {
      get() {
        return this.s_address;
      },
      set(value) {
        this[`${firmMutations.FIRM_PREFIX}${firmMutations.FIRM_SET_ADDRESS}`](value);
      },
    },
    estateAgentId: {
      get() {
        return this.s_estateAgentId;
      },
      set(value) {
        this[`${purchaseMutations.PURCHASE_PREFIX}${purchaseMutations.PURCHASE_SET_ESTATE_AGENT_ID}`](value);
      },
    },
    soldByEstateAgent: {
      get() {
        return {
          answer: this.s_soldByEstateAgent,
        };
      },
      set(value) {
        this[`${purchaseMutations.PURCHASE_PREFIX}${purchaseMutations.PURCHASE_SET_SOLD_BY_ESTATE_AGENT}`](
          value.answer,
        );
      },
    },
    solicitorContactDetails: {
      get() {
        return this.s_solicitorContactDetails;
      },
      set(value) {
        this[`${purchaseMutations.PURCHASE_PREFIX}${purchaseMutations.PURCHASE_SET_SOLICITOR_CONTACT_DETAILS}`](value);
      },
    },
  },
  methods: {
    ...mapMutations([
      `${firmMutations.FIRM_PREFIX}${firmMutations.FIRM_SET_NAME}`,
      `${firmMutations.FIRM_PREFIX}${firmMutations.FIRM_SET_PHONE}`,
      `${firmMutations.FIRM_PREFIX}${firmMutations.FIRM_SET_EMAIL}`,
      `${firmMutations.FIRM_PREFIX}${firmMutations.FIRM_SET_ADDRESS}`,
      `${purchaseMutations.PURCHASE_PREFIX}${purchaseMutations.PURCHASE_SET_ESTATE_AGENT_ID}`,
      `${purchaseMutations.PURCHASE_PREFIX}${purchaseMutations.PURCHASE_SET_SOLD_BY_ESTATE_AGENT}`,
      `${purchaseMutations.PURCHASE_PREFIX}${purchaseMutations.PURCHASE_SET_SOLICITOR_CONTACT_DETAILS}`,
    ]),
    async saveToApi() {
      const isValid = await this.$refs.observer.validate();
      if (!isValid) {
        this.$el.querySelector('[aria-invalid=true]').focus();
        return false; // If the return result is false, tab switch is restricted
      }
      this.saving = true;

      if (this.s_soldByEstateAgent) {
        // If sold by estate agent patch existing firm of if not exist create new one
        if (this.s_estateAgentId) {
          await patchFirm({
            id: this.s_estateAgentId,
            name: this.s_name,
            phone: this.s_phone,
            address: this.s_address,
            email: this.s_email,
          });
        } else {
          const postFirmResult = await postFirm({
            name: this.s_name,
            phone: this.s_phone,
            address: this.s_address,
            email: this.s_email,
          });
          //TODO: Verify that estate agent id will map from results
          const estateAgentId = postFirmResult.map((item) => item.data.Id);
          this[`${purchaseMutations.PURCHASE_PREFIX}${purchaseMutations.PURCHASE_SET_ESTATE_AGENT_ID}`](
            estateAgentId[0],
          );
        }
      }

      await patchPurchase({
        id: this.s_purchaseId,
        soldByEstateAgent: this.s_soldByEstateAgent,
        estateAgentId: this.s_estateAgentId,
        solicitorContactDetails: this.s_solicitorContactDetails,
      });

      this.saving = false;

      return true;
    },
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },
  },
  data: () => {
    return {
      yesOrNo,
    };
  },
};
</script>

<template>
  <validation-observer ref="observer">
    <h2>Seller Estate Agent</h2>

    <div class="row pb-1">
      <div class="col-md-12">
        <simple-question
          :dropdownOptions="yesOrNo"
          labelName="soldByEstateAgentLabelName"
          title="Is this property being sold by an Estate Agent?"
          v-model="soldByEstateAgent"
          id="soldByEstateAgent"
          validationFieldName="sold by Estate Agent"
        />
      </div>
    </div>

    <div v-if="soldByEstateAgent.answer">
      <div class="row pb-1">
        <div class="col-md-3">
          <h5>
            <label for="name" class="mandatory"> Estate Agent Name: </label>
          </h5>
        </div>

        <div class="col-md-9">
          <ValidationProvider name="name" rules="required" v-slot="validationContext">
            <b-form-input
              v-model="name"
              id="name"
              class="form-control"
              :state="getValidationState(validationContext)"
              placeholder="Estate Agent Name"
            ></b-form-input>
            <b-form-invalid-feedback data-error-name="name-error"
              >{{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </ValidationProvider>
        </div>
      </div>

      <div class="row pb-1">
        <div class="col-md-3">
          <h5>
            <label> Estate Agent Address: </label>
          </h5>
        </div>
      </div>

      <address-lookup v-model="address" id="address" :addResidency="false" :requiresValidation="false" />

      <div class="row pb-2">
        <div class="col-md-3">
          <h5>
            <label class="mandatory"> Estate Agent Telephone: </label>
          </h5>
        </div>

        <div class="col-md-9">
          <ValidationProvider
            name="estate agent telephone number"
            rules="phone_number|required"
            v-slot="validationContext"
          >
            <b-form-input
              v-model="phone"
              id="phone"
              class="form-control"
              :state="getValidationState(validationContext)"
              type="tel"
            ></b-form-input>
            <b-form-invalid-feedback data-error-name="phone-error"
              >{{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </ValidationProvider>
        </div>
      </div>

      <div class="row pb-2">
        <div class="col-md-3">
          <h5>
            <label class="mandatory"> Estate Agent Email: </label>
          </h5>
        </div>

        <div class="col-md-9">
          <ValidationProvider name="email" rules="email|required" v-slot="validationContext">
            <b-form-input
              v-model="email"
              id="email"
              class="form-control"
              :state="getValidationState(validationContext)"
              type="email"
            ></b-form-input>
            <b-form-invalid-feedback data-error-name="phone-error"
              >{{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </ValidationProvider>
        </div>
      </div>
    </div>
    <div class="row pb-2" v-else>
      <div class="col-md-3">
        <h5>
          <label for="solicitor-contact-details" class="mandatory">
            Solicitor contact details acting for the Seller:
          </label>
        </h5>
      </div>

      <div class="col-md-9">
        <validation-provider name="solicitor contact details" rules="required|min:3" v-slot="validationContext">
          <b-form-textarea
            v-model="solicitorContactDetails"
            id="solicitor-contact-details"
            rows="4"
            :state="getValidationState(validationContext)"
          ></b-form-textarea>
          <b-form-invalid-feedback v-if="validationContext.errors[0]" data-error-name="textarea-error"
            >{{ validationContext.errors[0] }}
          </b-form-invalid-feedback>
        </validation-provider>
      </div>
    </div>
  </validation-observer>
</template>

<style lang="scss" scoped></style>
