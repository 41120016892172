<script>
export default {
  name: 'PurchasingInJointNamesInfo',
};
</script>

<template>
  <div class="text-center">
    <i class="icon-info3 icon-2x text-info border-info border-3 rounded-round p-3 mb-3 mt-1"></i>
    <h3>IF YOU ARE PURCHASING IN JOINT NAMES</h3>
    <p>
      If there is more than one of you purchasing the property it is very important that you understand the different
      ways in which you can own the property. To try and simplify the way in which property transactions are dealt with,
      English and Welsh law recognises two different ways of holding the legal title to the property.
    </p>

    <p>
      These are broadly summarised as follows. Although the words “Tenancy” and “Tenants” are used below, these have
      nothing to do with renting property from a landlord.
    </p>

    <p class="my-2"><strong>Joint Tenancy</strong></p>
    <p>
      You hold the property as beneficial joint tenants. If one of you dies then the other inherits all of the property
      automatically regardless of any Will or other provision. Further, if you opt to hold as joint tenants, you cannot
      specify that the property is to be held otherwise than in equal shares.
    </p>

    <p>
      Please note that a Joint Tenancy can be severed by either party at any point during the joint ownership. This is a
      simple process and involves serving a Notice on the other owner. The effect of this Notice will be to convert the
      legal ownership into a Tenancy in Common in equal shares.
    </p>

    <p class="my-2"><strong>Tenancy in Common</strong></p>
    <p>
      You hold the property in shares. The shares may be equal, or may state a specific percentage allocated to each
      owner. You can each deal with these shares individually and they can be left by Will. If you own the property as
      tenants in common, then your share in the property will not automatically pass to the surviving owner on death,
      and your share in the property will be dealt with as part of your estate. If you wish to reflect the fact that one
      person has contributed more, financially or otherwise, to the purchase you may wish to consider opting to be
      tenants in common.
    </p>

    <p>
      From the above, it is hopefully clear that the choice as to how you should own the property is a very significant
      one. Which is best for you will depend very much on your circumstances. Please note that while we are able to
      explain the difference between the types of option in order to assist with your decision, we are not able to give
      any advice as to what option is most suitable for you in your circumstances. If you do wish to seek such advice,
      then we strongly advise that you seek independent legal advice from an independent solicitor qualified to advise
      in such matters."
    </p>
  </div>
</template>
